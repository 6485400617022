import { toRaw } from 'vue'
import mapboxgl from 'mapbox-gl'

import { distance, centroid, destination, point as turfPoint, polygon as turfPolygon, booleanPointInPolygon } from '@turf/turf'
import { useHead } from '@unhead/vue'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useNProgress } from '@vueuse/integrations/useNProgress'

const { progress, isLoading } = useNProgress(1)

import { PAGE_TITLE } from '@/brand'
import rollbar from '@/rollbar'

import { stopPropagation } from '@/tools/mapbox-map'
import { findClosestDate, wait, allSettledLimit } from '@/tools/helpers'
import { circle } from '@/tools/graphics'
import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'

import radarTowers from '@/data/radar_towers.js'
import { productGroups } from '@/data/radar_products.js'
import RadarColortable from '@/data/Colortables/Radar.js'

import BaseRadar from './Radar/BaseRadar'
import StormTracks from './Radar/StormTracks'
import Lightning from './Radar/Lightning'
import { RadarRenderer } from "./Radar/Renderer/";

import SimpleModal from './Modals/Templates/Simple.vue'
import RadarProductHelpModal from './Radar/RadarProductHelp.vue'

import api from '@/logic/Api'
import socket from '@/logic/Socket'

import { useRadarTowersStore, DEFAULT_RADAR_REF_PRODUCT_CODES, DEFAULT_RADAR_VEL_PRODUCT_CODES } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'

export default class Radar extends BaseRadar {
  constructor(map) {
    super(map)

    this.radarTowersStore = useRadarTowersStore()
    this.settings = useRadarSettingsStore()

    // Import colortables in settings into RadarColortable
    for(const groupId in this.settings.colortable_pal_upload) {
      const tables = toRaw(this.settings.colortable_pal_upload[groupId]);

      tables.forEach(t => {
        // Add a try/catch here just in case
        try {
          RadarColortable.parse(groupId, t.id, t.text);
        }
        catch(e) {
          console.error(`Failed to parse user radar colortable: ${groupId} ${t.id}`, t.text, e);

          rollbar.error(e, {
            groupId,
            tableId: t.id,
            tableText: t.text
          });
        }
      })
    }

    this.mode = MODE_NONE;

    this.sourceId = 'radar-towers-source'
    this.layerId = 'radar-towers-layer'
    this.radarRendererLayerId = 'radar-renderer';

    this.map.addLayer({
      id: 'top-top-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    })

    this.map.addLayer({
      id: 'bottom-top-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "top-top-radar-layer")

    this.map.addLayer({
      id: 'top-middle-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "land-structure-polygon")

    this.map.addLayer({
      id: 'bottom-middle-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "top-middle-radar-layer")

    this.stormTracks = new StormTracks(map, this);
    this.lightning = new Lightning(map, this);
    this.renderer = new RadarRenderer(map, this.radarRendererLayerId, "top-middle-radar-layer");

    this.bufferedRadarScans = {};
    this.bufferedRadarScansLimit = 0;
    this.playbackIdx = 0;

    this.activeSocketRooms = [];

    const copy = JSON.parse(JSON.stringify(radarTowers))

    copy.features = copy.features.map((f) => {
      const secondaryRadar = f.properties.secondary;

      f.properties.active = this.radarTowersStore.activeTowerId === f.properties.id;

      f.properties['icon-image'] = f.properties.secondary ? 'radar-tower-secondary' : 'radar-tower-primary';
      f.properties['icon-size'] = secondaryRadar ? 0.8 : 1
      f.properties['symbol-sort-key'] = secondaryRadar ? 2 : 1
      return f
    })

    this.towers = copy;

    // Generate and load icons
    const dotPrimary = circle({
      size: 21*2,
      color: '#10234E',
      border_color: '#FFFFFF',
      border_size: 3
    });
    this.map.addImage('radar-tower-primary', dotPrimary, { pixelRatio: 2 });

    const dotSecondary = circle({
      size: 21*2,
      color: '#F6BA12',
      border_color: '#FFFFFF',
      border_size: 3
    });
    this.map.addImage('radar-tower-secondary', dotSecondary, { pixelRatio: 2 });

    const dotActive = circle({
      size: 21*2,
      color: '#018101',
      border_color: '#FFFFFF',
      border_size: 3
    });
    this.map.addImage('radar-tower-active', dotActive, { pixelRatio: 2 });

    // Render radar markers
    this.map.addSource(this.sourceId, {
      type: 'geojson',
      // This will us the id from the 'properties' as the feature ID
      promoteId: 'id',
      data: this.towers
    })

    this.map.addLayer({
      id: this.layerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'icon-image': [
          'case',
          ['==', ['get', 'active'], true],
          'radar-tower-active',
          ['get', 'icon-image']
        ],
        'icon-size': ['get', 'icon-size'],
        'icon-padding': 1,
        'symbol-sort-key': [
          'case',
          ['==', ['get', 'active'], true],
          0,
          ['get', 'symbol-sort-key']
        ],
        'icon-pitch-alignment': 'map'
      }
    }, 'top-top-radar-layer')

    this.map.on('click', this.layerId, stopPropagation(async (e) => {
      // console.log('click',e)
      if(e.features.length === 0) return;

      const feature = this.towers.features.find((f) => f.properties.id === e.features[0].properties.id);
      if (feature === undefined) return;

      // console.log(feature)

      const inspectorActive = this.radarTowersStore.inspectorActive;

      if (feature.properties.active) {
        this.closeAllRadarTowers()
      } else {
        // Close all other radar towers
        this.closeAllRadarTowers()

        this.turnOnRadar(feature)

        if(inspectorActive) {
          this.radarTowersStore.inspectorActive = true;
        }
      }
    }))

    const activeTower = this.towers.features.find(f => f.properties.active);
    if(activeTower !== undefined) {
      this.turnOnRadar(activeTower)
    }
  }

  getRenderer() {
    return this.renderer;
  }

  clearPlaybackState() {
    // Return mdoe back to realtime
    this.mode = MODE_REALTIME;

    // Stop playing incase it is
    this.radarTowersStore.isPlaying = false;

    // Clear radar buffered state
    this.clearBufferedHistoricalState();

    // Return lightning back to realtime (if not)
    this.lightning.clearBufferedHistoricalState();

    // Return warnigns back to realtime (if not)
    this.map.warnings.clearBufferedHistoricalState();

    // Return warnigns back to realtime (if not)
    this.map.mesoscaleDiscussions.clearBufferedHistoricalState();

    // Reset playback scan index
    this.playbackIdx = 0;
  }

  closeAllRadarTowers() {
    this.clearPlaybackState();

    this.radarTowersStore.inspectorActive = false;

    this.towers.features.forEach((f) => {
      if (! f.properties.active) return

      this.removeRadar(f)
      f.properties.active = false
    });

    this.map.getSource(this.sourceId).setData(toRaw(this.towers))
  }

  async turnOnRadar(feature, product = null) {
    useHead({
      title: `${feature.properties.code} - ${PAGE_TITLE}`
    })

    // console.log(product, this.radarTowersStore.activeProductCode)

    // If no product has been provided
    // And there is product code defined in the store
    // Let's default to reflectivty.
    // Failing that, default to velocity
    if(product === null && this.radarTowersStore.activeProductCode.length === 0) {
      for(const code of DEFAULT_RADAR_REF_PRODUCT_CODES) {
        if(feature.properties.products.includes(code)) {
          product = code;
          break;
        }
      }

      // If still null, try velocity
      if(product === null) {
        for(const code of DEFAULT_RADAR_VEL_PRODUCT_CODES) {
          if(feature.properties.products.includes(code)) {
            product = code;
            break;
          }
        }
      }

      // If still null, throw exception
      if(product === null) {
        throw new Error(`Failed to locate a default radar product for: ${feature.properties.id}`);
      }
    }
    else if(product === null && this.radarTowersStore.activeProductCode.length > 0) {
      product = this.radarTowersStore.activeProductCode
    }

    if(! feature.properties.products.includes(product)) {
      const group = productGroups.find(g => {
        const tilt = g.tilts.find(t => {
          return t.product === product;
        })

        return tilt !== undefined;
      })

      if(group === undefined) {
        return console.error(`Failed to locate product group for product: ${product}`);
      }

      const groups = productGroups.filter(g => g.id === group.id);

      for(const g of groups) {
        const firstProductFromSameGroup = g.tilts.find(t => {
          return feature.properties.products.includes(t.product);
        });

        if(firstProductFromSameGroup !== undefined) {
          product = firstProductFromSameGroup.product;
          break;
        }
      }

      if(! feature.properties.products.includes(product)) {
        for(const code of DEFAULT_RADAR_REF_PRODUCT_CODES) {
          if(feature.properties.products.includes(code)) {
            product = code;
            break;
          }
        }

        if(! feature.properties.products.includes(product)) {
          for(const code of DEFAULT_RADAR_VEL_PRODUCT_CODES) {
            if(feature.properties.products.includes(code)) {
              product = code;
              break;
            }
          }
        }
      }
    }

    // console.log(product, this.radarTowersStore.activeProductCode)

    // console.log('a', feature, product)

    this.mode = MODE_REALTIME;

    feature.properties.active = true
    this.map.getSource(this.sourceId).setData(toRaw(this.towers))

    this.radarTowersStore.setActiveTower(feature, product)
    this.radarTowersStore.clearScanDatetime()
    this.radarTowersStore.clearScanVcp()

    const room = `radar:${feature.properties.id}:${product}`
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Radar update', room, data, `Mode: ${this.mode}`)

      if(this.mode === MODE_REALTIME) {
        // Load the url provided in the data
        await this.loadScan(feature, product, data.url)
      }
      else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
        if(this.bufferedRadarScans[product] === undefined) return false;

        try {
          const scan = await this.fetchFileByUrl(data.url);

          if(this.bufferedRadarScans[product] === undefined) return false;

          this.bufferedRadarScans[product].push(scan);

          while(this.bufferedRadarScans[product].length > this.bufferedRadarScansLimit) {
            this.bufferedRadarScans[product].shift();
          }
        }
        catch(e) {
          console.error(`Failed to fetch scan in historical to realtime mode`, e);
        }
      }
    })

    console.time("Load radar data");
    await this.loadRadarData(feature, product);
    console.timeEnd("Load radar data");
  }

  isLightningSupported(feature) {
    // This is roughly the bounds of GOES-16 (East)
    const goesEastBounds = [-139.8023604410725,-81.5046269257671,-39.0234375,72.8001832];
    const bounds = new mapboxgl.LngLatBounds([goesEastBounds[0], goesEastBounds[1]], [goesEastBounds[2], goesEastBounds[3]]);
    return bounds.contains(feature.geometry.coordinates);
  }

  async loadRadarData(feature, product) {
    const promises = [];

    promises.push(this.loadLatestScan(feature, product))

    if (feature.properties.products.includes('NST') && this.settings.storm_tracks > 0) {
      promises.push(this.loadLatestStormTracks(feature))
    }

    if(this.isLightningSupported(feature) && this.settings.lightning > 0) {
      promises.push(this.lightning.load(feature.properties.id));
    }

    const response = await Promise.allSettled(promises);
    response.filter(r => r.status === 'rejected').forEach(r => console.warn(r.reason))
  }

  async turnOnRadarViaId(id, product = null) {
    const feature = this.towers.features.find(f => f.properties.id === id)

    if(feature === undefined) return false

    this.closeAllRadarTowers()

    await this.turnOnRadar(feature, product)

    return feature
  }

  async changeRadarProduct(id, product) {
    const feature = this.towers.features.find(f => f.properties.id === id)

    if(feature === undefined) return false

    this.clearPlaybackState()
    this.stopListeningToRooms()

    this.radarTowersStore.setActiveProductCode(product);

    await this.turnOnRadar(feature)
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  removeRadar(feature) {
    useHead({
      title: PAGE_TITLE
    })

    this.radarTowersStore.clear()

    const towerId = feature.properties.id;

    this.stopListeningToRooms();

    this.renderer.clear();

    this.stormTracks.clear(towerId);

    this.lightning.clear(towerId);
  }

  getClosestTowers(position) {
    const majorTowers = this.towers.features.filter(f => !f.properties.secondary).map(f => {
      return {
        feature: f,
        distance: distance(f.geometry.coordinates, position, { units: "kilometers" })
      }
    })

    majorTowers.sort((a, b) => {
      return a.distance - b.distance;
    })

    return majorTowers;
  }

  async turnOnClosestRadar(position) {
    const majorTowers = this.getClosestTowers(position);

    if(majorTowers.length == 0) return;

    if(this.radarTowersStore.activeTowerId === majorTowers[0].feature.properties.id) return;

    this.closeAllRadarTowers()

    await this.turnOnRadar(majorTowers[0].feature)
  }

  async turnOnClosestOnlineRadar(position, product = 'REF') {
    // Now locate the nearest ONLINE radar (within 300 kilometers)
    // And turn on the lowest elevation ref product
    const closestTowers = this.map.radar.getClosestTowers(position).filter(f => f.distance < 300);

    if(closestTowers.length === 0) {
      let message = 'Could not locate a nearby radar tower.'
      throw new Error(message)
    }

    const defaultProductCodes = (() => {
      if(product === 'REF') return DEFAULT_RADAR_REF_PRODUCT_CODES;
      else if(product === 'VEL') return DEFAULT_RADAR_VEL_PRODUCT_CODES;

      return [];
    })();

    // Loop through each close tower until we find a ref. scan
    // That is not too old (max 10 mins.)
    for (const t of closestTowers) {
      const tower = t.feature;
      const towerId = tower.properties.id;
      const product = (() => {
        for(const p of defaultProductCodes) {
          if(tower.properties.products.includes(p)) return p;
        }

        return null;
      })();

      if(product === null) continue;

      const scan = await this.loadLatestFile(towerId, product);

      const age = moment.utc().diff(moment.utc(scan.datetime), 'seconds');

      // Check if scan is too old
      if(age < 60 * 10) {
        this.closeAllRadarTowers();
        await this.turnOnRadar(tower, product);
        break;
      }
    }
  }

  async turnOnBestRadarForGeometry(geometry, product = 'REF') {
    // Now locate the nearest ONLINE radar (within 300 kilometers)
    // Loop through all the available 'product' scans
    // Find the 'best' which has the highest average value for
    // gates inside the geometry
    const center = centroid(geometry)
    const closestTowers = this.map.radar.getClosestTowers(center).filter(f => f.distance < 300).slice(0, 4);

    if(closestTowers.length === 0) {
      throw new Error('Could not locate a nearby radar tower.')
    }

    const tPolygon = turfPolygon(geometry.coordinates)

    const defaultProductCodes = (() => {
      if(product === 'REF') return DEFAULT_RADAR_REF_PRODUCT_CODES;
      else if(product === 'VEL') return DEFAULT_RADAR_VEL_PRODUCT_CODES;

      return [];
    })();

    const scoredScans = [];

    // Loop through each close tower until we find a ref. scan
    // That is not too old (max 10 mins.)
    for (const t of closestTowers) {
      const tower = t.feature;
      const towerId = tower.properties.id;

      const availableProducts = tower.properties.products.filter(value => defaultProductCodes.includes(value));

      if(availableProducts.length === 0) continue;

      for (const product of availableProducts) {
        const scan = await this.loadLatestFile(towerId, product);

        // console.log(scan)

        const age = moment.utc().diff(moment.utc(scan.datetime), 'seconds');

        // If older than 10 minutes, ignore...
        if(age > 60 * 10) continue;

        let inside = 0;
        let insideSum = 0;
        let outside = 0;

        const bitsMax = scan.precision - 1;
        const bitsValueMax = (1 << bitsMax) - 1;

        let e = 0;
        let len = scan.data.length;
        let val = 0;
        let tval = 0;
        let fval = 0;
        let skip = 0;
        let idx = 0;
        let r = 0;
        let g = 0;

        const degreePerRow = 360 / scan.dims[0];

        // Loop through each 16 bit value
        while(e < len) {
          val = scan.data[e];

          // Check if the value is higher than the max value for 15 bits
          if(val > bitsValueMax) {
            skip = val - bitsValueMax;

            ++e;

            idx+=skip;

            continue;
          }

          // Convert 15 bits to 16 bits...
          fval = scan.range.min + (val / bitsValueMax) * (scan.range.max - scan.range.min);

          r = Math.floor(idx / scan.dims[1]);
          g = idx % scan.dims[1];

          // Require a min. of 15dbz
          if(fval >= 10) {
            const radius_m = scan.meters_to_center_of_first_gate + ((g + 0.5) * scan.meters_between_gates);
            const azimuth = (scan.azimuth_start + ((r + 0.5) * degreePerRow)) % 360.0;

            // console.log(r, g, radius_m, azimuth, scan.meters_to_center_of_first_gate, scan.meters_between_gates)

            const gatePosition = destination(scan.location, radius_m, azimuth, { units: 'meters' });

            // console.log(towerId, product, idx, r, g, scan.azimuth_start, radius_m, azimuth, fval, gatePosition)
            // window.map.setCenter(gatePosition.geometry.coordinates)
            // window.map.setZoom(20)
            // break;

            if(booleanPointInPolygon(gatePosition, tPolygon)) {
              ++inside
              insideSum+=fval
            }
            else {
              ++outside
            }
          }

          idx+=1;

          ++e;
        }

        if(inside > 0) {
          const insideMean = insideSum/inside;

          scoredScans.push([insideMean, [towerId, product]])
        }

        break;
      }

      break;
    }

    // console.log(scoredScans)

    if(scoredScans.length === 0) {
      throw new Error('Unable to find any available (interesting) radar scan');
    }

    scoredScans.sort((a, b) => {
      return b[0] - a[0]
    })

    const highestScore = scoredScans[0][1]

    this.closeAllRadarTowers();

    const [towerId, productCode] = highestScore;

    // console.log('Best combo', towerId, productCode)

    await this.turnOnRadarViaId(towerId, productCode);
  }

  async loadLatestScan(feature, product) {
    const towerId = feature.properties.id

    const scan = await this.loadLatestFile(towerId, product);

    this.drawScan(scan, towerId, product)
  }

  async loadScan(feature, product, url) {
    const towerId = feature.properties.id

    try {
      const scan = await this.fetchFileByUrl(url);

      this.drawScan(scan, towerId, product)
    } catch (error) {
      console.error(error.message);
    }
  }

  drawScan(scan, towerId, product) {
    if(scan === undefined || scan === null || typeof scan !== 'object') {
      return console.log(`Bad radar scan: ${towerId} ${product}`);
    }

    // Check that the tower is still active
    if(!(this.radarTowersStore.activeTowerId === towerId && this.radarTowersStore.activeProductCode === product)) {
      return console.log(`Loaded radar scan for a tower that is no longer active: ${towerId} ${product}`)
    }

    // We need to convert low level product code to higher level
    // e.g. N0B is REF
    const group = productGroups.find(g => {
      const tilt = g.tilts.find(t => {
        return t.product === product;
      })

      return tilt !== undefined;
    })

    if(group === undefined) {
      return console.error(`Failed to locate product group for product: ${product}`);
    }

    const colormapKey = this.settings.colortable[group.id];

    const colormap = RadarColortable.get(group.id, colormapKey)

    if(colormap === null) {
      return console.error(`Failed to any colortables for product: ${product} ${group.id} ${colormapKey}`);
    }

    // console.log({colormap, product})
    this.renderer.setColormap(colormap);
    this.renderer.draw(scan);

    this.radarTowersStore.setColorMap(colormap)
    this.radarTowersStore.setScanDatetime(scan.datetime)

    const vcp = scan?.metadata?.vcp ?? null;
    this.radarTowersStore.setScanVcp(vcp);
  }

  async loadLatestStormTracks(feature) {
    const towerId = feature.properties.id

    const product = 'NST'
    const room = `radar:${towerId}:${product}`
    if(! this.activeSocketRooms.includes(room)) {
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('Radar update', room, data, `Mode: ${this.mode}`)

        if(this.mode === MODE_REALTIME) {
          // Load the url provided in the data
          await this.loadStormTracks(feature, data.url)
        }
        else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
          if(this.bufferedRadarScans[product] === undefined) return false;

          try {
            const scan = await this.fetchFileByUrl(data.url);

            if(this.bufferedRadarScans[product] === undefined) return false;

            this.bufferedRadarScans[product].push(scan);

            while(this.bufferedRadarScans[product].length > this.bufferedRadarScansLimit) {
              this.bufferedRadarScans[product].shift();
            }
          }
          catch(e) {
            console.error(`Failed to fetch scan in historical to realtime mode`, e);
          }
        }
      })
    }

    const tracks = await this.loadLatestFile(towerId, 'NST')

    if(!(this.radarTowersStore.activeTowerId === towerId)) {
      return console.log(`Loaded radar scan for a tower that is no longer active: ${towerId} ${product}`)
    }

    this.drawStormTracks(towerId, tracks);
  }

  async loadStormTracks(feature, url) {
    const towerId = feature.properties.id

    try {
      const tracks = await api.instance().get(url);

      this.drawStormTracks(towerId, tracks);
    } catch (error) {
      console.error(error.message);
    }
  }

  drawStormTracks(towerId, tracks) {
    // Check that the tracks are actually 'new'
    const ageMins = moment.utc().diff(moment.utc(tracks.datetime), 'minutes');

    // If older than 20 minutes, don't draw
    if(ageMins > 20) return;

    this.stormTracks.draw(towerId, tracks)
  }

  clearStormTracks(towerId) {
    this.stormTracks.clear(towerId);

    const product = 'NST'
    const room = `radar:${towerId}:${product}`

    socket.roomLeave(room)
    socket.removeAllListeners(room)

    this.activeSocketRooms = this.activeSocketRooms.filter(r => r !== room);
  }

  async loadHistory(towerId, product, limit) {
    if(limit === undefined) {
      limit = this.settings.max_scans;
    }

    // Check if we're already buffered data for this product
    // If so, exit early
    if(typeof this.bufferedRadarScans[product] === 'object' && this.bufferedRadarScans[product].length <= limit) {
      return false;
    }
    this.bufferedRadarScansLimit = limit;

    const feature = this.towers.features.find((f) => f.properties.id === towerId)
    if (feature === undefined) return;

    // Clear previously buffered state
    this.clearPlaybackState();

    // Now set the back
    // The clear function above will set it back to realtime
    this.mode = MODE_HISTORICAL_TO_REALTIME;

    const bufferNST = feature.properties.products.includes('NST') && this.settings.storm_tracks > 0;
    const bufferLightning = this.isLightningSupported(feature) && this.settings.lightning > 0;
    const bufferWarnings = true;
    const bufferMesoscaleDiscussions = true;

    let requestsToMake = 0;

    let percentIncrement = 0;

    progress.value = 0.1;

    const limitedProductList = await this.fetchLatestList(towerId, product, limit);
    requestsToMake+=limitedProductList.length;
    const requestsProduct = limitedProductList.map((file) => {
      return () => {
        return new Promise(async (resolve, reject) => {
          try {
            const r = await this.fetchFile(towerId, product, file);
            progress.value+=percentIncrement
            resolve(r)
          }
          catch(e) {
            reject(e);
          }
        })
      }
    });

    // console.log({requestsProduct})
    // return

    let requestsNST = null;
    if(bufferNST) {
      try {
        const limitedNSTList = await this.fetchLatestList(towerId, 'NST', limit);
        requestsToMake+=limitedNSTList.length;
        requestsNST = limitedNSTList.map((file) => {
          return () => {
            return new Promise(async (resolve, reject) => {
              try {
                const r = await this.fetchFile(towerId, 'NST', file);
                progress.value+=percentIncrement
                resolve(r)
              }
              catch(e) {
                reject(e);
              }
            })
          }
        });
      }
      catch(e) {
        console.error('Failed to fetch NST list', e);
      }
    }

    progress.value = 0.2;

    // Subtract 0.1 for the requests made below the radar products (warnings, etc.)
    percentIncrement = (1.0 - progress.value - 0.1) / requestsToMake;

    // console.log(requestsToMake, percentIncrement)

    // This will need a rethink once the limit is higher
    const response = await allSettledLimit(requestsProduct, 6)

    this.bufferedRadarScans[product] = response.filter(r => r.status === 'fulfilled').map(r => r.value);

    if(bufferNST && requestsNST !== null) {
      // This will need a rethink once the limit is higher
      const response = await allSettledLimit(requestsNST, 6)

      this.bufferedRadarScans['NST'] = response.filter(r => r.status === 'fulfilled').map(r => r.value);
    }

    // We'll assume there is a radar scan every 6 mins. on average
    // NEXRAD is every 3-6
    // CAN is every 6
    const secsToLoad = 60 * 60;

    const promises = [];

    if(bufferLightning) {
      promises.push(this.lightning.loadHistory(towerId, secsToLoad));
    }

    if(bufferWarnings) {
      promises.push(this.map.warnings.loadHistory(secsToLoad));
    }

    if(bufferMesoscaleDiscussions) {
      promises.push(this.map.mesoscaleDiscussions.loadHistory(secsToLoad));
    }

    await Promise.allSettled(promises);

    this.radarTowersStore.hasBufferedScans = true;

    progress.value = 1.0
  }

  clearBufferedHistoricalState() {
    for(const key in this.bufferedRadarScans) {
      delete this.bufferedRadarScans[key]
    }

    this.radarTowersStore.hasBufferedScans = false;
  }

  async playScans(towerId, product, maxSteps = Infinity) {
    // If data has no been buffered, then exit early
    if(this.bufferedRadarScans[product] === undefined) return false;

    const feature = this.towers.features.find((f) => f.properties.id === towerId)
    if (feature === undefined) return;

    const bufferedHistoricalToRealtime = true;

    let playNST = feature.properties.products.includes('NST') && typeof this.bufferedRadarScans['NST'] === 'object';
    let playLightning = true;
    let playWarnings = true;
    let playMesoscaleDiscussions = true;

    if(window.location.href.includes('test-radar-scans')) {
      playNST = false;
      playLightning = false;
      playWarnings = false;
      playMesoscaleDiscussions = false;
    }

    this.radarTowersStore.isPlaying = true;

    let currentStep = 0;

    while(this.radarTowersStore.isPlaying && currentStep < maxSteps) {
      if(map.isUserInteracting) {
        console.log('Skiping playback due to interaction');
      }
      else {
        const length = this.bufferedRadarScans[product].length;
        if(this.playbackIdx < 0) {
          this.playbackIdx = 0;
        }
        else if(this.playbackIdx >= length) {
          this.playbackIdx = length - 1;
        }

        const scan = this.bufferedRadarScans[product][this.playbackIdx];

        // Ensure that there is a scan here...
        if(scan) {
          const dt = scan.datetime;
          const momentDt = moment.utc(dt);

          this.drawScan(scan, towerId, product);

          const isLastScan = (this.playbackIdx + 1) === length;
          const displayFuture = bufferedHistoricalToRealtime && isLastScan;

          if(playNST) {
            const NSTdates = this.bufferedRadarScans['NST'].map(s => s.datetime)

            const closest = findClosestDate(NSTdates, momentDt);

            // Check if the closest date is within 15 minutes
            const closestNST = (closest !== null && Math.abs(moment.utc(closest).diff(momentDt, 'minutes')) <= 15) ? closest : null;

            if(closestNST !== null) {
              const tracks = this.bufferedRadarScans['NST'].find(s => s.datetime === closestNST);

              this.stormTracks.draw(towerId, tracks);
            }
          }

          if(playLightning) {
            this.lightning.drawHistory(momentDt, displayFuture);
          }

          if(playWarnings) {
            this.map.warnings.drawHistory(momentDt, displayFuture);
          }

          if(playMesoscaleDiscussions) {
            this.map.mesoscaleDiscussions.drawHistory(momentDt, displayFuture);
          }
        }

        ++currentStep;
        ++this.playbackIdx;
        if(this.playbackIdx >= length) this.playbackIdx = 0;
      }

      let delay = this.settings.playback_speed_ms;
      if(this.playbackIdx === 0) {
        delay*=4;
      }

      await wait(delay);
    }
  }

  openRadarProductHelpModal(productGroup) {
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: productGroup.name
      },
      slots: {
        default: useModalSlot({
          component: RadarProductHelpModal,
          attrs: {
            text: productGroup.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  show() {
    for(const layerId of [this.layerId, this.radarRendererLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }

    this.lightning.show();
    this.stormTracks.show();
  }

  hide() {
    for(const layerId of [this.layerId, this.radarRendererLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }

    this.clearPlaybackState();

    this.lightning.hide();
    this.stormTracks.hide();
  }
}
