export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -8.919994, 52.692787, 92.0
      ]
    },
    "properties": {
      "id": "IESHA",
      "code": "IESHA",
      "secondary": false,
      "products": [
        "REF",
      ],
      "elevations": [0.5, 1.1, 2.2, 3.2, 4.3, 6.9, 8.5, 10.1, 20.0, 90.0]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -6.2443, 53.4299, 99.0
      ]
    },
    "properties": {
      "id": "IEDUB",
      "code": "IEDUB",
      "secondary": false,
      "products": [
        "REF",
      ],
      "elevations": [0.5, 1.1, 1.9, 2.9, 4.0, 5.4, 7.2, 10.0, 11.9, 15.0]
    }
  }
]