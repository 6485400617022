export default [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          24.6021,59.3977,32.7
        ]
      },
      "properties": {
        "id": "EEHAR",
        "code": "EEHAR",
        "secondary": false,
        "products": [
          "REF",
          "VEL",
          "ZDR",
          "KDP",
          "CC",
        ],
        "elevations": [0.5, 1.3, 2, 3, 4.5, 6.5, 9]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          25.5187,58.4823,13
        ]
      },
      "properties": {
        "id": "EESUR",
        "code": "EESUR",
        "secondary": false,
        "products": [
          "REF",
          "VEL",
          "ZDR",
          "KDP",
          "CC",
        ],
        "elevations": [0.5, 1.3, 2, 3, 4.5, 6.5, 9]
      }
    }
  ]