<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { VueFinalModal } from 'vue-final-modal'

defineProps<{
    title?: string
}>()

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: boolean): void
}>()
</script>

<template>
    <VueFinalModal content-transition="vfm-fade" overlay-transition="vfm-fade" content-class="absolute inset-0"
        :lock-scroll="false" @update:model-value="(val) => emit('update:modelValue', val)">
        <div class="overflow-auto flex h-full justify-center pt-[calc(10%)] dark:bg-gray-900/50"
            @click.self="() => emit('update:modelValue', false)">
            <div class="flex flex-col w-full md:max-w-[60%]">
                <div class="flex items-center relative">
                    <div class="absolute top-0 right-0 z-50">
                        <button @click="emit('update:modelValue', false); emit('close')" class="p-[5px] sm:px-[10px]">
                            <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-white" />
                        </button>
                    </div>
                    <div class="text-gray-900 dark:text-slate-300 max-w-full">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
