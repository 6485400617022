import { defineStore } from 'pinia';

interface MapLayersSettings {
  mapLayerOn: boolean;
}

const defaults: MapLayersSettings = {
  mapLayerOn: false,
};

export const useHazcamsStore = defineStore('settings/vendors/hazcams', {
  state: (): MapLayersSettings => ({
    ...JSON.parse(JSON.stringify(defaults))
  }),

  actions: {
    toggle() {
      this.mapLayerOn = !this.mapLayerOn;
    },

    set(value: boolean) {
      this.mapLayerOn = value;
    },

    reset() {
      this.$state = JSON.parse(JSON.stringify(defaults));
    }
  },

  persist: true
});
