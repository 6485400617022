import { point, bearing, destination } from '@turf/turf'
import moment from 'moment'

import { stopPropagation } from '@/tools/mapbox-map'
import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'

import socket from '@/logic/Socket'
import api from '@/logic/Api'

export default class Lightning {
  constructor(map, radar) {
    this.map = map
    this.radar = radar;

    this.pointsSourceId = 'radar-lightning-strikes-source'
    this.strikeIconLayerId = 'radar-lightning-strikes-icon-layer'
    this.strikeDotLayerId = 'radar-lightning-strikes-dot-layer'

    this.layers = [
      this.strikeIconLayerId,
      this.strikeDotLayerId
    ];

    this.mode = MODE_NONE;

    // 15 mins... ?
    this.MAX_STRIKE_AGE = 15 * 60;

    this.bufferedStrikes = [];
    this.bufferedMaxAge = 0;

    this.collection = {
      type: 'FeatureCollection',
      features: []
    }

    setInterval(() => {
      const nowEpoch = moment.utc().unix();
      this.collection.features = this.collection.features.filter((f) => {
        return (nowEpoch - f.properties.at) < this.MAX_STRIKE_AGE;
      }).map((f) => {
        let opacity = 1 - ((nowEpoch - f.properties.at) / this.MAX_STRIKE_AGE);
        if(opacity > 1) opacity = 1;
        else if(opacity < 0.1) opacity = 0.1;
        f.properties['icon-opacity'] = opacity;
        return f;
      });

      this.draw();
    }, 60 * 1000);

    // Add source/layer for points
    this.map.addSource(this.pointsSourceId, {
      type: 'geojson',
      data: this.collection
    })

    this.sourcePoints = this.map.getSource(this.pointsSourceId);

    this.map.addLayer({
      id: this.strikeIconLayerId,
      type: 'symbol',
      source: this.pointsSourceId,
      layout: {
        'icon-image': ["get", "icon-image"],
        'icon-size': 0.05,
        'icon-padding': 0,
        'icon-pitch-alignment': 'map',
        'symbol-sort-key': ["-", 1, ["get", "icon-opacity"]],
        'visibility': 'none'
      },
      paint: {
        'icon-opacity': ["get", "icon-opacity"]
      },
    });

    this.map.addLayer({
      id: this.strikeDotLayerId,
      type: 'circle',
      source: this.pointsSourceId,
      layout: {
        'visibility': 'none'
      },
      paint: {
        'circle-radius': 4,
        'circle-radius': 3,
        'circle-stroke-width': 1,
        'circle-color': 'yellow',
        'circle-color': 'white',
        'circle-stroke-color': '#000000',
        'circle-opacity': ["get", "icon-opacity"],
      },
    });
  }

  strikesToFeatures(strikes, dtUnix) {
    if(dtUnix === undefined) {
      dtUnix = moment.utc().unix();;
    }

    const features = strikes.filter(s => {
      return (dtUnix - s[2]) < this.MAX_STRIKE_AGE;
    }).map((t, i) => {
      let opacity = 1 - ((dtUnix - t[2]) / this.MAX_STRIKE_AGE);
      if(opacity > 1) opacity = 1;
      else if(opacity < 0.1) opacity = 0.1;

      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: t },
        properties: {
          id: `${Math.random()}`,
          at: t[2],
          'icon-image': 'radar-lightning-strikes-strike',
          'icon-opacity': opacity
        }
      }
    });

    return features;
  }

  push(features) {
    this.collection.features.push(...features)

    return this;
  }

  draw() {
    this.sourcePoints.setData(this.collection)
  }

  async loadIcon() {
    if(! this.map.hasImage('radar-lightning-strikes-strike')) {
      await this.map.asyncLoadAndAddImage('/radar/lightning/strike.png', 'radar-lightning-strikes-strike')
    }
  }

  listenForLightningUpdates(towerId) {
    const room = `radar-lightning-strikes:${towerId}`
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Radar lightning strike', room, data, `Mode: ${this.mode}`)

      if(this.mode === MODE_REALTIME) {
        const features = this.strikesToFeatures(data.strikes);

        this.push(features).draw();
      }
      else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
        this.bufferedStrikes.push(...data.strikes);

        // Now filter out strikes than the max
        const dtUnix = moment.utc().unix();
        this.bufferedStrikes = this.bufferedStrikes.filter((s) => {
          return s[2] <= dtUnix && s[2] >= (dtUnix - (this.bufferedMaxAge))
        });
      }
    })
  }

  async load(towerId) {
    await this.loadIcon()

    this.mode = MODE_REALTIME;

    // Load past strikes for some interval
    try {
      const lightning = await api.instance().get(`/satellite/radar/${towerId}/active.json`);

      const features = this.strikesToFeatures(lightning.strikes);

      this.push(features).draw();
    }
    catch(e) {
      console.error(`Failed to fetch lightning data for: ${towerId}`, e);
    }

    this.listenForLightningUpdates(towerId);
  }

  async loadHistory(towerId, secsToLoad) {
    await this.loadIcon()

    this.mode = MODE_HISTORICAL_TO_REALTIME;

    this.bufferedStrikes = [];
    this.bufferedMaxAge = secsToLoad;

    try {
      const lightning = await api.instance().get(`/satellite/radar/${towerId}/active-${secsToLoad}.json`);

      this.bufferedStrikes = lightning.strikes;
    }
    catch(e) {
      console.error(`Failed to fetch historical lightning data for: ${towerId}`, e);
    }
  }

  drawHistory(dt, displayFuture) {
    if(typeof dt === 'string') {
      dt = moment.utc(dt);
    }

    const dtUnix = dt.unix();
    // console.log(dt, m)
    // console.log(this.bufferedStrikes)

    const filterFn = displayFuture ? (s) => {
      return s[2] >= (dtUnix - (this.MAX_STRIKE_AGE))
    } : (s) => {
      return s[2] <= dtUnix && s[2] >= (dtUnix - (this.MAX_STRIKE_AGE))
    };

    const filtered = this.bufferedStrikes.filter(filterFn);

    // console.log(filtered.length)

    this.collection.features = this.strikesToFeatures(filtered, dtUnix);
    this.draw();
  }

  clearBufferedHistoricalState() {
    this.bufferedStrikes = [];

    this.mode = MODE_REALTIME;
  }

  clear(towerId) {
    this.bufferedStrikes = [];

    this.collection.features = [];
    this.draw();

    this.mode = MODE_NONE;

    const room = `radar-lightning-strikes:${towerId}`
    socket.roomLeave(room)
    socket.removeAllListeners(room)
  }

  show() {
    this.hide()

    const level = this.radar.settings.lightning;
    if(level === 1) {
      this.map.setLayoutProperty(this.strikeIconLayerId, 'visibility', 'visible');
    }
    else if(level === 2) {
      this.map.setLayoutProperty(this.strikeDotLayerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of this.layers) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}
