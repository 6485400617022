export const isCanvasColorMajority = function(canvas, targetColors) {
    // Ensure targetColors is an array of arrays
    const colors = Array.isArray(targetColors[0]) ? targetColors : [targetColors];

    // Get WebGL context and pixel data
    const gl = canvas.getContext('webgl2');

    const pixels = new Uint8Array(gl.drawingBufferWidth * gl.drawingBufferHeight * 4);
    gl.readPixels(
        0, 0,
        gl.drawingBufferWidth, gl.drawingBufferHeight,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        pixels
    );

    // Analyze pixels
    const totalPixels = gl.drawingBufferWidth * gl.drawingBufferHeight;
    let matchingPixels = 0;

    for (let i = 0; i < pixels.length; i += 4) {
        const currentPixel = [
            pixels[i],
            pixels[i + 1],
            pixels[i + 2],
            pixels[i + 3]
        ];

        const isMatch = colors.some(targetColor =>
            targetColor[0] === currentPixel[0] &&
            targetColor[1] === currentPixel[1] &&
            targetColor[2] === currentPixel[2] &&
            targetColor[3] === currentPixel[3]
        );

        if (isMatch) matchingPixels++;
    }

    return matchingPixels / totalPixels > 0.5;
};

window.isCanvasColorMajority = isCanvasColorMajority