import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HazcamsView from '../views/HazcamsView.vue'
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/hazcams/:id',
      name: 'hazcams',
      component: HazcamsView,
      meta: {
        bodyClass: 'page-hazcams-station'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Remove the old body class (if any) from the previous route
  if (from.meta.bodyClass) {
    document.body.classList.remove(from.meta.bodyClass)
  }

  // Add a new body class from the incoming route
  if (to.meta.bodyClass) {
    document.body.classList.add(to.meta.bodyClass)
  }

  next()
})


export default router
