import api from '@/logic/Api'

import RadarColortable from '@/data/Colortables/Radar.js'

import Packet from '@/logic/WxBin/Packet'

export default class BaseRadar {
  constructor(map) {
    this.map = map
  }

  async loadLatestFile(id, product) {
    const list = await this.fetchLatestList(id, product)

    // console.log(list)

    if (list.length == 0) {
      // TODO
      return
    }

    const latestScanFilename = list[list.length - 1]

    // console.log(latestScanFilename)

    // const latestScanFilename = 'latest.json';

    return await this.fetchFile(id, product, latestScanFilename)
  }

  async fetchLatestList(id, product, limit = -1) {
    let list;

    if(window.location.href.includes('test-radar-scans')) {
      list = await (await fetch('/test-radar-scans/dir.list')).text()
    }
    else {
      list = await (
        await api.instance().get(`/radar/processed/${id}/${product}/dir.list`)
      )
    }

    const cleanList = list.split('\n').filter((l) => l.length > 0);

    if(limit === -1) return cleanList;

    return cleanList.slice(Math.max(cleanList.length - limit, 0))
  }

  async fetchFile(id, product, filename) {
    let url = `/radar/processed/${id}/${product}/${filename}`;

    if(window.location.href.includes('test-radar-scans')) {
      url =`/test-radar-scans/${filename}`;
    }

    return await this.fetchFileByUrl(url);
  }

  async fetchFileByUrl(url) {
    // TODO: Add proper parser to check if extension is .wise
    // if(url.endsWith('.wise')) {
    //   const response = await api.instance().get(url, {
    //     responseType: 'stream'
    //   });
      
    //   const colormap = RadarColortable.get('REF', 'DEFAULT');
    //   window.map.radar.renderer.setColormap(colormap);

    //   return await Packet.stream(response, (scan) => {
    //     // Update
    //     window.map.radar.renderer.draw(scan);
    //   });
    // }

    const response = await api.instance().get(url, {
      responseType: 'arraybuffer'
    });

    const packet = new Packet(response);

    if (packet.isBinary()) {
      return packet.unpack();
    }

    // If it's not binary, then we can assume it's JSON
    return packet.json();
  }
}
