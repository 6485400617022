import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

import { removeExpired } from './helpers'
import api from '../logic/Api'

import { MODE_REALTIME } from '@/tools/constants'

const CHECK_FOR_EXPIRED_INTERVAL = 61 * 1000

let init = false

export const useMesoscaleDiscussionsStore = defineStore('mesoscale_discussions', {
  state: () => ({
    geojson: {
      type: 'FeatureCollection',
      features: []
    },
    last_update_at: null,
    loading: false,
  }),

  // persist: {
  //   afterRestore: (ctx) => {
  //     // filter out expired mesoscale discussions
  //     ctx.store.geojson.features = removeExpired(toRaw(ctx.store.geojson.features))
  //   }
  // },

  actions: {
    init() {
      if (init) return
      init = true

      setInterval(() => {
        if(window.map.mesoscaleDiscussions.getMode() !== MODE_REALTIME) return;

        const features = removeExpired(toRaw(this.geojson.features));

        if(this.geojson.features.length !== features.length) {
          this.geojson.features = features;
        }
      }, CHECK_FOR_EXPIRED_INTERVAL)
    },

    async load() {
      if(this.loading) return;

      try {
        this.loading = true;
        const geojson = await api.instance().get(`/mesoscale-discussions/USA.geojson?_=${(new Date()).getTime()}`)

        geojson.features = removeExpired(geojson.features)

        this.geojson = geojson
        this.last_update_at = moment.utc().toISOString()
      } catch (error) {
        console.log(error)
        return error
      } finally {
        this.loading = false;
      }
    },

    push(feature) {
      // Check that the warning is not expired
      const now = moment.utc()
      const expiresAt = moment.utc(feature.properties.expires_at)

      if(expiresAt.isBefore(now)) return;

      this.geojson.features.push(feature)
    },

    filter(product) {
      return this.geojson.features.filter((f) => {
        return f.properties.product === product
      })
    },

    getFeatureById(id, raw = false) {
      const manip = raw ? toRaw : (f) => { return f; };
      return manip(this.geojson.features.find(f => f.properties.id === id));
    }
  }
})
