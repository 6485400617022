import { defineStore } from 'pinia';

interface MapLayersSettings {
  positionsMapLayerOn: boolean;
}

const defaults: MapLayersSettings = {
  positionsMapLayerOn: false,
};

export const useSpotterNetworkStore = defineStore('settings/vendors/spotter-network', {
  state: (): MapLayersSettings => ({
    ...JSON.parse(JSON.stringify(defaults))
  }),

  actions: {
    toggle() {
      this.positionsMapLayerOn = !this.positionsMapLayerOn;
    },

    turnOnPositionsMapLayer() {
      this.positionsMapLayerOn = true;
    },

    turnOffPositionsMapLayer() {
      this.positionsMapLayerOn = false;
    },

    reset() {
      this.$state = JSON.parse(JSON.stringify(defaults));
    }
  },

  persist: true
});
