// https://www.weather.gov/media/tg/noaaport_radar_products.pdf
export const polarProducts = [
	'NSW',
	'NXB',
	'NYB',
	'NZB',
	'N0B',
	'NAB',
	'N1B',
	'NBB',
	'N2B',
	'N3B',
	'NXG',
	'NYG',
	'NZG',
	'N0G',
	'NAG',
	'N1G',
	'NBU',
	'N2U',
	'N3U',
	'DHR',
	'DPA',
	'NCR',
	'EET',
	'NVW',
	'N0S',
	'N1S',
	'N2S',
	'N3S',
	'NVL',
	'DVL',
	'NXX',
	'NYX',
	'NZX',
	'N0X',
	'NAX',
	'N1X',
	'NBX',
	'N2X',
	'N3X',
	'NXC',
	'NYC',
	'NZC',
	'N0C',
	'NAC',
	'N1C',
	'NBC',
	'N2C',
	'N3C',
	'NXK',
	'NYK',
	'NZK',
	'N0K',
	'NAK',
	'N1K',
	'NBK',
	'N2K',
	'N3K',
	'NXH',
	'NYH',
	'NZH',
	'N0H',
	'NAH',
	'N1H',
	'NBH',
	'N2H',
	'N3H',
	'NXM',
	'NYM',
	'NZM',
	'N0M',
	'NAM',
	'N1M',
	'NBM',
	'N2M',
	'N3M',
	'HHC',
	'NRR',
	'DPR',
	'OHA',
	'DAA',
	'DTA',
	'DU3',
	'DU6',
	'TZL',
	'TZ0',
	'TZ1',
	'TZ2',
	'TV0',
	'TV1',
	'TV2',
	'REF0',
	'REF1',
	'REF2',
	'REF3',
	'REF4',
	'REF5',
	'REF6',
	'REF7',
	'REF8',
	'REF9',
	'VEL0',
	'VEL1',
	'VEL2',
	'VEL3',
	'VEL4',
	'VEL5',
	'VEL6',
	'VEL7',
	'VEL8',
	'VEL9',
	'CC0',
	'CC1',
	'CC2',
	'CC3',
	'CC4',
	'CC5',
	'CC6',
	'CC7',
	'CC8',
	'CC9',
	'ZDR0',
	'ZDR1',
	'ZDR2',
	'ZDR3',
	'ZDR4',
	'ZDR5',
	'ZDR6',
	'ZDR7',
	'ZDR8',
	'ZDR9',
	'KDP0',
	'KDP1',
	'KDP2',
	'KDP3',
	'KDP4',
	'KDP5',
	'KDP6',
	'KDP7',
	'KDP8',
	'KDP9',
	'SW0',
	'SW1',
	'SW2',
	'SW3',
	'SW4',
	'SW5',
	'SW6',
	'SW7',
	'SW8',
	'SW9',
	'PRT'
];

const helpTextRef = `Reflectivity refers to the ability of radar waves to bounce back (or reflect) off objects in the atmosphere, such as raindrops, snowflakes, hailstones, or even birds and insects. It is a measure of how much of the radar's transmitted signal is returned to the radar receiver. Reflectivity is typically expressed in decibels of reflectivity (dBZ), which is a logarithmic unit.

The stronger the reflection, the higher the dBZ value, which usually correlates with more intense precipitation. For example:

- Low reflectivity values (around 0 to 20 dBZ) may indicate light rain or drizzle.
- Moderate reflectivity values (20 to 40 dBZ) suggest moderate rain or snow.
- High reflectivity values (40 to 60+ dBZ) are often associated with heavy rainfall, thunderstorms, or even hail.

Reflectivity maps generated by weather radar help meteorologists identify and track storms, assess precipitation intensity, and sometimes infer the type of precipitation occurring.`;

const helpTextVel = `Velocity in weather radar refers to the measurement of the speed and direction of objects, such as raindrops or snow, as they move toward or away from the radar. This is made possible using the Doppler effect, which causes the frequency of the radar waves to change depending on the motion of the target.

In weather radar, velocity data helps determine the wind speed and direction within storms. Positive velocity values typically indicate that the objects are moving away from the radar (outbound), while negative values suggest movement toward the radar (inbound). This information is crucial for detecting rotation within storms, which can be an indicator of tornado formation, as well as for tracking general wind patterns and storm movement.`;

export const productGroups = [
	{
		id: "REF",
		name: 'Reflectivity',
		unit: 'dBZ',
		tilts: [
			{
				elevation: '-0.2',
				product: 'NXB'
			},
			{
				elevation: '0.0 - 0.2',
				product: 'NYB'
			},
			{
				elevation: '0.3 - 0.4',
				product: 'NZB'
			},
			{
				elevation: '0.5',
				product: 'N0B'
			},
			{
				elevation: '0.9',
				product: 'NAB'
			},
			{
				elevation: '1.3 - 1.5',
				product: 'N1B'
			},
			{
				elevation: '1.8',
				product: 'NBB'
			},
			{
				elevation: '2.4 - 2.5',
				product: 'N2B'
			},
			{
				elevation: '3.1 - 3.5',
				product: 'N3B'
			},
			{
				elevation: '0.1 - 0.8',
				product: 'TZ0'
			},
			{
				elevation: '1.0',
				product: 'TZ1'
			},
			{
				elevation: '1.6 - 3.7',
				product: 'TZ2'
			},
			{
				elevation: null,
				product: 'REF0'
			},
			{
				elevation: null,
				product: 'REF1'
			},
			{
				elevation: null,
				product: 'REF2'
			},
			{
				elevation: null,
				product: 'REF3'
			},
			{
				elevation: null,
				product: 'REF4'
			},
			{
				elevation: null,
				product: 'REF5'
			},
			{
				elevation: null,
				product: 'REF6'
			},
			{
				elevation: null,
				product: 'REF7'
			},
			{
				elevation: null,
				product: 'REF8'
			},
			{
				elevation: null,
				product: 'REF9'
			},
		],
		help: helpTextRef
	},
	{
		id: 'REF',
		name: 'Long Range Reflectivity',
		unit: 'dBZ',
		tilts: [
			{
				elevation: '0.1 - 0.8',
				product: 'TZL'
			}
		],
		help: helpTextRef
	},
	{
		id: "VEL",
		name: 'Velocity',
		unit: 'm/s',
		tilts: [
			{
				elevation: '-0.2',
				product: 'NXG'
			},
			{
				elevation: '0.0 - 0.2',
				product: 'NYG'
			},
			{
				elevation: '0.3 - 0.4',
				product: 'NZG'
			},
			{
				elevation: '0.5',
				product: 'N0G'
			},
			{
				elevation: '0.9',
				product: 'NAG'
			},
			{
				elevation: '1.3 - 1.5',
				product: 'N1G'
			},
			{
				elevation: '1.8',
				product: 'NBU'
			},
			{
				elevation: '2.4 - 2.5',
				product: 'N2U'
			},
			{
				elevation: '3.1 - 3.5',
				product: 'N3U'
			},
			{
				elevation: '0.1 - 0.8',
				product: 'TV0'
			},
			{
				elevation: '1.0',
				product: 'TV1'
			},
			{
				elevation: '1.6 - 3.7',
				product: 'TV2'
			},
			{
				elevation: null,
				product: 'VEL0'
			},
			{
				elevation: null,
				product: 'VEL1'
			},
			{
				elevation: null,
				product: 'VEL2'
			},
			{
				elevation: null,
				product: 'VEL3'
			},
			{
				elevation: null,
				product: 'VEL4'
			},
			{
				elevation: null,
				product: 'VEL5'
			},
			{
				elevation: null,
				product: 'VEL6'
			},
			{
				elevation: null,
				product: 'VEL7'
			},
			{
				elevation: null,
				product: 'VEL8'
			},
			{
				elevation: null,
				product: 'VEL9'
			},
		],
		help: helpTextVel
	},
	{
		id: "CC",
		name: 'Correlation Coefficient',
		unit: '',
		tilts: [
			// {
			// 	elevation: '-0.2',
			// 	product: 'NXC'
			// },
			// {
			// 	elevation: '0.0 - 0.2',
			// 	product: 'NYC'
			// },
			// {
			// 	elevation: '0.3 - 0.4',
			// 	product: 'NZC'
			// },
			// {
			// 	elevation: '0.5',
			// 	product: 'N0C'
			// },
			// {
			// 	elevation: '0.9',
			// 	product: 'NAC'
			// },
			// {
			// 	elevation: '1.3 - 1.5',
			// 	product: 'N1C'
			// },
			// {
			// 	elevation: '1.8',
			// 	product: 'NBC'
			// },
			// {
			// 	elevation: '2.4 - 2.5',
			// 	product: 'N2C'
			// },
			// {
			// 	elevation: '3.1 - 3.5',
			// 	product: 'N3C'
			// },
			{
				elevation: null,
				product: 'CC0'
			},
			{
				elevation: null,
				product: 'CC1'
			},
			{
				elevation: null,
				product: 'CC2'
			},
			{
				elevation: null,
				product: 'CC3'
			},
			{
				elevation: null,
				product: 'CC4'
			},
			{
				elevation: null,
				product: 'CC5'
			},
			{
				elevation: null,
				product: 'CC6'
			},
			{
				elevation: null,
				product: 'CC7'
			},
			{
				elevation: null,
				product: 'CC8'
			},
			{
				elevation: null,
				product: 'CC9'
			},
		],
		help: `The correlation coefficient in weather radar is a measure of how similarly different radar signals are behaving within a radar pulse volume. It specifically compares the horizontal and vertical polarization of radar returns, which helps meteorologists differentiate between types of precipitation or atmospheric objects.

Correlation coefficient values range from 0 to 1:

- High values (close to 1) indicate that objects in the radar beam are similar in shape and size, such as uniform raindrops or snowflakes.
- Lower values (closer to 0) suggest a mix of different types of objects, like hail mixed with rain or non-meteorological targets like debris from a tornado.

This measurement is especially valuable for identifying areas of hail, debris, or mixed precipitation and can be crucial in detecting tornado debris signatures, often referred to as a "debris ball" on radar.`
	},
	{
		id: "PRT",
		name: 'Precipitation Type',
		unit: 'dBZ',
		tilts: [
			{
				elevation: (feature) => {
					if(feature.properties.elevations !== undefined && Array.isArray(feature.properties.elevations)) {
						return feature.properties.elevations[0];
					}

					const refProductGroup = productGroups.find(g => g.id === 'REF');

					for(const product of feature.properties.products) {
						const productInGroup = refProductGroup.tilts.find(t => t.product === product);

						if(productInGroup !== undefined) return productInGroup.elevation;
					}

					return null;
				},
				product: 'PRT'
			}
		],
		help: 'The Precipitation Type radar product provides a real-time classification of the type of precipitation reaching the ground. Using advanced algorithms and the weather radar we identify rain, snow and a mix, making it easier to understand current weather conditions. This product is especially useful during winter storms and mixed precipitation events, offering clear insights into surface conditions.'
	},
	{
		id: "ZDR",
		name: 'Differential Reflectivity',
		unit: 'db',
		tilts: [
			// {
			// 	elevation: '-0.2',
			// 	product: 'NXX'
			// },
			// {
			// 	elevation: '0.0 - 0.2',
			// 	product: 'NYX'
			// },
			// {
			// 	elevation: '0.3 - 0.4',
			// 	product: 'NZX'
			// },
			// {
			// 	elevation: '0.5',
			// 	product: 'N0X'
			// },
			// {
			// 	elevation: '0.9',
			// 	product: 'NAX'
			// },
			// {
			// 	elevation: '1.3 - 1.5',
			// 	product: 'N1X'
			// },
			// {
			// 	elevation: '1.8',
			// 	product: 'NBX'
			// },
			// {
			// 	elevation: '2.4 - 2.5',
			// 	product: 'N2X'
			// },
			// {
			// 	elevation: '3.1 - 3.5',
			// 	product: 'N3X'
			// },
			{
				elevation: null,
				product: 'ZDR0'
			},
			{
				elevation: null,
				product: 'ZDR1'
			},
			{
				elevation: null,
				product: 'ZDR2'
			},
			{
				elevation: null,
				product: 'ZDR3'
			},
			{
				elevation: null,
				product: 'ZDR4'
			},
			{
				elevation: null,
				product: 'ZDR5'
			},
			{
				elevation: null,
				product: 'ZDR6'
			},
			{
				elevation: null,
				product: 'ZDR7'
			},
			{
				elevation: null,
				product: 'ZDR8'
			},
			{
				elevation: null,
				product: 'ZDR9'
			},
		],
		help: `Differential reflectivity (ZDR) is a weather radar measurement that compares the difference in reflectivity between horizontally and vertically polarized radar waves. It provides insight into the shape and orientation of objects in the atmosphere, such as raindrops or ice particles.

- Positive ZDR values indicate that objects are wider horizontally than vertically, such as raindrops, which tend to flatten as they fall.
- Negative or near-zero ZDR values suggest more spherical or irregularly shaped objects, such as snowflakes, hail, or tumbling ice particles.

By analyzing differential reflectivity, meteorologists can determine the type of precipitation (rain, snow, hail) and assess the size and shape of raindrops, which is important for identifying areas of heavy rain or hail. It also helps in distinguishing between rain and snow or identifying melting layers in the atmosphere.`
	},
	{
		id: "SW",
		name: 'Spectrum Width',
		unit: 'mph',
		tilts: [
			// {
			// 	elevation: '0.0',
			// 	product: 'NSW'
			// },
			{
				elevation: null,
				product: 'SW0'
			},{
				elevation: null,
				product: 'SW1'
			},{
				elevation: null,
				product: 'SW2'
			},{
				elevation: null,
				product: 'SW3'
			},{
				elevation: null,
				product: 'SW4'
			},{
				elevation: null,
				product: 'SW5'
			},{
				elevation: null,
				product: 'SW6'
			},{
				elevation: null,
				product: 'SW7'
			},{
				elevation: null,
				product: 'SW8'
			},{
				elevation: null,
				product: 'SW9'
			},
		],
		help: `Spectrum Width is a weather radar product that measures the variability, or dispersion, in the velocity of precipitation particles within a radar beam. Essentially, it represents the range or spread of velocities of objects (such as raindrops, snowflakes, or debris) moving through the radar beam.

Key insights from spectrum width:

- Low spectrum width: Indicates uniform motion, where most particles are moving at similar speeds, typical in steady or smooth wind flows.
- High spectrum width: Suggests a wide range of particle velocities, which often occurs in turbulent areas, such as in thunderstorms, near updrafts or downdrafts, or around areas of strong wind shear.

Spectrum width is useful for:

- Identifying turbulence: Areas of high spectrum width often correspond to regions of storm turbulence, making it a valuable tool for aviation and storm analysis.
- Detecting storm rotation: When used in conjunction with other radar products, high spectrum width can highlight areas of potential tornado formation or strong wind shear.
- Distinguishing between different meteorological and non-meteorological targets: It can help separate clear-air echoes (such as birds or insects) from weather-related phenomena.

In summary, spectrum width provides a snapshot of the variability in particle motion within a storm, helping to identify turbulence and storm dynamics that may not be visible in standard velocity products.`
	},
	{
		id: "KDP",
		name: 'Specific Diff. Phase',
		unit: 'Deg/km',
		tilts: [
			{
				elevation: '-0.2',
				product: 'NXK'
			},
			{
				elevation: '0.0 - 0.2',
				product: 'NYK'
			},
			{
				elevation: '0.3 - 0.4',
				product: 'NZK'
			},
			{
				elevation: '0.5',
				product: 'N0K'
			},
			{
				elevation: '0.9',
				product: 'NAK'
			},
			{
				elevation: '1.3 - 1.5',
				product: 'N1K'
			},
			{
				elevation: '1.8',
				product: 'NBK'
			},
			{
				elevation: '2.4 - 2.5',
				product: 'N2K'
			},
			{
				elevation: '3.1 - 3.5',
				product: 'N3K'
			},
			{
				elevation: null,
				product: 'KDP0'
			},
			{
				elevation: null,
				product: 'KDP1'
			},
			{
				elevation: null,
				product: 'KDP2'
			},
			{
				elevation: null,
				product: 'KDP3'
			},
			{
				elevation: null,
				product: 'KDP4'
			},
			{
				elevation: null,
				product: 'KDP5'
			},
			{
				elevation: null,
				product: 'KDP6'
			},
			{
				elevation: null,
				product: 'KDP7'
			},
			{
				elevation: null,
				product: 'KDP8'
			},
			{
				elevation: null,
				product: 'KDP9'
			},
		],
		help: `Specific Differential Phase (KDP) is a weather radar measurement that quantifies the phase shift between horizontally and vertically polarized radar waves as they pass through precipitation. It is primarily used to estimate the intensity and type of precipitation by measuring how much the radar waves slow down when interacting with precipitation particles.

- Higher KDP values indicate areas with more intense precipitation, such as heavy rain, where the radar waves experience a greater phase shift.
- Lower or near-zero KDP values suggest lighter precipitation or ice particles, which do not cause as much phase shift.

Specific Differential Phase is particularly useful for estimating rainfall rates because it directly responds to the amount of liquid water in the radar beam, without being affected by factors like hail or non-meteorological objects. It helps improve the accuracy of precipitation estimates and can differentiate between rain and mixed-phase precipitation like hail or snow.`
	},
	{
		id: "HCA",
		name: 'Hydrometeor Classification',
		unit: '', // variable
		tilts: [
			{
				elevation: '-0.2',
				product: 'NXH'
			},
			{
				elevation: '0.0 - 0.2',
				product: 'NYH'
			},
			{
				elevation: '0.3 - 0.4',
				product: 'NZH'
			},
			{
				elevation: '0.5',
				product: 'N0H'
			},
			{
				elevation: '0.9',
				product: 'NAH'
			},
			{
				elevation: '1.3 - 1.5',
				product: 'N1H'
			},
			{
				elevation: '1.8',
				product: 'NBH'
			},
			{
				elevation: '2.4 - 2.5',
				product: 'N2H'
			},
			{
				elevation: '3.1 - 3.5',
				product: 'N3H'
			},
		],
		help: `Hydrometeor Classification is a weather radar technique that identifies and categorizes different types of precipitation particles (hydrometeors) and non-precipitation objects in the atmosphere. This is achieved by analyzing several radar variables, such as reflectivity, differential reflectivity (ZDR), correlation coefficient, and specific differential phase (KDP), and then using algorithms to classify the detected objects.

Common classifications in hydrometeor classification include:

- Rain: Liquid droplets that vary in size.
- Snow: Ice crystals or snowflakes.
- Hail: Hard, round ice particles.
- Graupel: Soft, small pellets of ice formed when supercooled water droplets freeze onto snowflakes.
- Mixed precipitation: A combination of rain and snow or sleet.
- Non-meteorological targets: Objects such as birds, insects, or ground clutter.

This classification is especially useful for improving the accuracy of precipitation type identification, particularly in complex weather systems like thunderstorms, winter storms, or mixed-phase precipitation events. It aids meteorologists in distinguishing between rain, snow, sleet, and hail, and in identifying debris in severe storms, which can be a sign of a tornado.`
	},
	// TODO
	// Come back to this later
	// {
	// 	name: 'Hybrid Scan Reflectivity',
	// 	tilts: [
	// 		{
	// 			elevation: '0.0',
	// 			product: 'DHR'
	// 		}
	// 	]
	// },
	{
		name: 'Precipitation Array',
		tilts: [
			{
				elevation: '0.0',
				product: 'DPA'
			}
		]
	},
	{
		name: 'Composite Reflectivity - 16 Data Levels – 124 nmi Range',
		tilts: [
			{
				elevation: '0.0',
				product: 'NCR'
			}
		]
	},
	{
		id: 'EET',
		name: 'Enhanced Echo Tops',
		unit: 'kft',
		tilts: [
			{
				elevation: '0.0',
				product: 'EET'
			}
		],
		help: `Enhanced Echo Tops refer to the highest altitude within a storm where radar detects significant reflectivity (typically around 18 dBZ or greater). They provide an estimate of the top of the strongest part of the storm, indicating the extent of the convective activity.

Enhanced echo tops are used to assess storm intensity and vertical development, as taller storms typically indicate stronger updrafts and the potential for severe weather such as hail, strong winds, and possibly tornadoes. By examining the height of echo tops, meteorologists can gauge the overall strength of thunderstorms and identify regions with intense upward motion.

These measurements are particularly helpful for aviation, as they allow pilots to avoid the most dangerous areas of storms, and for forecasters tracking the potential severity of weather systems.`
	},
	{
		name: 'Velocity Azimuth Display Wind Profile',
		tilts: [
			{
				elevation: '0.0',
				product: 'NVW'
			}
		]
	},
	{
		id: "SRV",
		name: 'Storm Relative Velocity',
		unit: 'm/s',
		tilts: [
			{
				elevation: '0.5',
				product: 'N0S'
			},
			{
				elevation: '1.3',
				product: 'N1S'
			},
			{
				elevation: '2.4',
				product: 'N2S'
			},
			{
				elevation: '3.1',
				product: 'N3S'
			}
		],
		help: `Storm Relative Velocity (SRV) is a weather radar product that shows the motion of precipitation and air relative to the movement of a storm itself. It subtracts the storm’s overall motion from the radar's velocity data to focus on the internal wind patterns within the storm.

This is especially useful for identifying key storm features such as:

- Rotation: SRV highlights areas where winds are moving toward and away from the radar in close proximity, which can indicate rotation within a storm and help detect developing tornadoes.
- Inflow and outflow: It helps visualize how air is flowing into and out of the storm, providing clues about storm structure and intensity.

By using SRV, meteorologists can more effectively track the dynamics of severe thunderstorms, including supercells and tornadoes, as it isolates internal motion from the overall storm drift, making it easier to spot features like mesocyclones (rotating updrafts).`
	},
	// nothing on the data server for this
	{
		id: "VIL",
		name: 'Vertical Integrated Liquid',
		unit: 'kg/m2',
		tilts: [
			{
				elevation: '0.0',
				product: 'NVL'
			}
		]
	},
	// This is digital VIL
	{
		id: "VIL",
		name: 'Vertical Integrated Liquid',
		unit: 'kg/m2',
		tilts: [
			{
				elevation: '0.0',
				product: 'DVL'
			}
		],
		help: `Vertically Integrated Liquid (VIL) is a weather radar product that estimates the total amount of liquid water (in kilograms per square meter) present in a column of the atmosphere above a given point. It is calculated by integrating the reflectivity values detected by radar throughout the vertical extent of a storm.

Higher VIL values typically indicate stronger storms with greater concentrations of liquid water or ice, such as:

- Heavy rain: High VIL values suggest areas of intense precipitation.
- Hail: Very high VIL values may indicate the presence of hail within a storm, as hailstones can contribute significantly to radar reflectivity.

VIL is commonly used to assess storm intensity and the potential for severe weather, including heavy rainfall, hail, and even the likelihood of flash flooding. It is particularly useful for identifying strong convective storms and regions of high precipitation concentration, providing a quick snapshot of a storm’s water content and severity.`
	},
	// More info about this product can be found here: https://www.weather.gov/media/lmk/soo/Dual_Pol_Overview.pdf
	// {
	// 	name: 'Melting Layer',
	// 	tilts: [
	// 		{
	// 			elevation: '-0.2',
	// 			product: 'NXM'
	// 		},
	// 		{
	// 			elevation: '0.0 - 0.2',
	// 			product: 'NYM'
	// 		},
	// 		{
	// 			elevation: '0.3 - 0.4',
	// 			product: 'NZM'
	// 		},
	// 		{
	// 			elevation: '0.5',
	// 			product: 'N0M'
	// 		},
	// 		{
	// 			elevation: '0.9',
	// 			product: 'NAM'
	// 		},
	// 		{
	// 			elevation: '1.3 - 1.5',
	// 			product: 'N1M'
	// 		},
	// 		{
	// 			elevation: '1.8',
	// 			product: 'NBM'
	// 		},
	// 		{
	// 			elevation: '2.4 - 2.5',
	// 			product: 'N2M'
	// 		},
	// 		{
	// 			elevation: '3.1 - 3.5',
	// 			product: 'N3M'
	// 		},
	// 	]
	// },
	{
		name: 'Hybrid Scan Hydrometeor Classification',
		tilts: [
			{
				elevation: '0.0',
				product: 'HHC'
			}
		]
	},
	{
		name: 'Hybrid Scan Rain Rate Classification',
		tilts: [
			{
				elevation: '0.0',
				product: 'NRR'
			}
		]
	},
	{
		name: 'Digital Inst. Precip. Rate (in/hr)',
		tilts: [
			{
				elevation: '0.0',
				product: 'DPR'
			}
		]
	},
	{
		id: "OHA",
		name: 'One Hour Precip. Accum.',
		unit: 'IN',
		tilts: [
			{
				elevation: '0.0',
				product: 'OHA'
			}
		]
	},
	{
		id: "OHA",
		name: 'One Hour Precip. Accum.',
		unit: 'IN',
		tilts: [
			{
				elevation: '0.0',
				product: 'DAA'
			}
		],
		help: `1-Hour Precipitation Accumulation is a weather radar product that estimates the total amount of precipitation (usually in millimeters or inches) that has fallen over a specific area during the past hour. It is derived by using radar reflectivity data and algorithms to estimate rainfall rates, then summing those rates over the course of an hour.

This product is particularly useful for:

- Monitoring rainfall intensity: It helps meteorologists assess how much rain has fallen in a short period, which is critical for identifying areas at risk of flash flooding.
- Tracking short-term precipitation trends: It provides a snapshot of recent rainfall to understand how weather systems are evolving.
- Flood forecasting and emergency planning: By highlighting areas with heavy rainfall in the last hour, authorities can issue timely flood warnings.

1-Hour Precipitation Accumulation is an important tool for real-time decision-making in weather-sensitive operations like agriculture, transportation, and flood management.`
	},
	{
		id: "DTA",
		name: 'Storm Total Precip. Accum.',
		unit: 'IN',
		tilts: [
			{
				elevation: '0.0',
				product: 'DTA'
			}
		],
		help: `Storm Total Precipitation Accumulation is a weather radar product that estimates the total amount of precipitation (in millimeters or inches) that has fallen over a specific area during the entire duration of a storm or weather event. This product aggregates rainfall over an extended period, from the start of the storm until it ends or until the radar is reset.

It is valuable for:

- Assessing storm impact: By providing a complete picture of how much rain or snow has fallen, it helps gauge the severity of a weather event.
- Flood forecasting: High storm total precipitation can indicate areas at risk of flooding, especially when the ground becomes saturated.
- Water management and hydrology: It supports decisions about water resources, river levels, and drainage systems in the aftermath of storms.
- Agriculture and disaster response: Knowing the total precipitation helps farmers, planners, and emergency services respond effectively to the storm’s aftermath.

Storm Total Precipitation Accumulation gives a broader overview of the total precipitation impact over the course of the storm, helping to evaluate cumulative effects like flooding or water damage.`
	},
	{
		name: 'Dig. User-Selectable Accum.: 3hr/hourly',
		tilts: [
			{
				elevation: '0.0',
				product: 'DU3'
			}
		]
	},
	{
		name: 'Dig. User-Selectable Accum.: 24hr/12Z',
		tilts: [
			{
				elevation: '0.0',
				product: 'DU6'
			}
		]
	}
]

export const products = {
	'GSM': 'General Status Message',
	'FTM': 'Free Text Message',
	// WSR-88D products
	'NSW': 'Base Spectrum Width',
	'NXB': 'Base Reflectivity – 248 nmi Range (-0.2)',
	'NYB': 'Base Reflectivity – 248 nmi Range (0.0, 0.2)',
	'NZB': 'Base Reflectivity – 248 nmi Range (0.3, 0.4)',
	'N0B': 'Base Reflectivity – 248 nmi Range (0.5)',
	'NAB': 'Base Reflectivity – 248 nmi Range (0.9)',
	'N1B': 'Base Reflectivity – 248 nmi Range (1.3, 1.5)',
	'NBB': 'Base Reflectivity – 248 nmi Range (1.8)',
	'N2B': 'Base Reflectivity – 248 nmi Range (2.4, 2.5)',
	'N3B': 'Base Reflectivity – 248 nmi Range (3.1, 3.4, 3.5)',
	'NXG': 'Base Radial Velocity - 162 nmi Range (-0.2)',
	'NYG': 'Base Radial Velocity - 162 nmi Range (0.0, 0.2)',
	'NZG': 'Base Radial Velocity - 162 nmi Range (0.3, 0.4)',
	'N0G': 'Base Radial Velocity - 162 nmi Range (0.5)',
	'NAG': 'Base Radial Velocity - 162 nmi Range (0.9)',
	'N1G': 'Base Radial Velocity - 162 nmi Range (1.3, 1.5)',
	'NBU': 'Base Radial Velocity - 162 nmi Range (1.8)',
	'N2U': 'Base Radial Velocity - 162 nmi Range (2.4, 2.5)',
	'N3U': 'Base Radial Velocity - 162 nmi Range (3.1, 3.4, 3.5)',
	'DHR': 'Digital Hybrid Scan Reflectivity',
	'DPA': 'Digital Precipitation Array',
	'NCR': 'Composite Reflectivity - 16 Data Levels – 124 nmi Range',
	'EET': 'Enhanced Echo Tops',
	'NVW': 'Velocity Azimuth Display Wind Profile',
	'N0S': 'Storm Relative Mean Radial Velocity',
	'N1S': 'Storm Relative Mean Radial Velocity',
	'N2S': 'Storm Relative Mean Radial Velocity',
	'N3S': 'Storm Relative Mean Radial Velocity',
	'NVL': 'Vertical Integrated Liquid',
	'DVL': 'Digital Vertical Integrated Liquid',
	'NST': 'Storm Tracking Information',
	'NMD': 'Mesocyclone',
	'NXX': 'Digital Differential Reflectivity (-0.2)',
	'NYX': 'Digital Differential Reflectivity (0.0, 0.2)',
	'NZX': 'Digital Differential Reflectivity (0.3, 0.4)',
	'N0X': 'Digital Differential Reflectivity (0.5)',
	'NAX': 'Digital Differential Reflectivity (0.9)',
	'N1X': 'Digital Differential Reflectivity (1.3, 1.5)',
	'NBX': 'Digital Differential Reflectivity (1.8)',
	'N2X': 'Digital Differential Reflectivity (2.4, 2.5)',
	'N3X': 'Digital Differential Reflectivity (3.1, 3.4, 3.5)',
	'NXC': 'Digital Correlation Coefficient (-0.2)',
	'NYC': 'Digital Correlation Coefficient (0.0, 0.2)',
	'NZC': 'Digital Correlation Coefficient (0.3, 0.4)',
	'N0C': 'Digital Correlation Coefficient (0.5)',
	'NAC': 'Digital Correlation Coefficient (0.9)',
	'N1C': 'Digital Correlation Coefficient (1.3, 1.5)',
	'NBC': 'Digital Correlation Coefficient (1.8)',
	'N2C': 'Digital Correlation Coefficient (2.4, 2.5)',
	'N3C': 'Digital Correlation Coefficient (3.1, 3.4, 3.5)',
	'NXK': 'Digital Specific Differential Phase (-0.2)',
	'NYK': 'Digital Specific Differential Phase (0.0, 0.2)',
	'NZK': 'Digital Specific Differential Phase (0.3, 0.4)',
	'N0K': 'Digital Specific Differential Phase (0.5)',
	'NAK': 'Digital Specific Differential Phase (0.9)',
	'N1K': 'Digital Specific Differential Phase (1.3, 1.5)',
	'NBK': 'Digital Specific Differential Phase (1.8)',
	'N2K': 'Digital Specific Differential Phase (2.4, 2.5)',
	'N3K': 'Digital Specific Differential Phase (3.1, 3.4, 3.5)',
	'NXH': 'Digital Hydrometeor Classification (-0.2)',
	'NYH': 'Digital Hydrometeor Classification (0.0, 0.2)',
	'NZH': 'Digital Hydrometeor Classification (0.3, 0.4)',
	'N0H': 'Digital Hydrometeor Classification (0.5)',
	'NAH': 'Digital Hydrometeor Classification (0.9)',
	'N1H': 'Digital Hydrometeor Classification (1.3, 1.5)',
	'NBH': 'Digital Hydrometeor Classification (1.8)',
	'N2H': 'Digital Hydrometeor Classification (2.4, 2.5)',
	'N3H': 'Digital Hydrometeor Classification (3.1, 3.4, 3.5)',
	'NXM': 'Melting Layer (-0.2)',
	'NYM': 'Melting Layer (0.0, 0.2)',
	'NZM': 'Melting Layer (0.3, 0.4)',
	'N0M': 'Melting Layer (0.5)',
	'NAM': 'Melting Layer (0.9)',
	'N1M': 'Melting Layer (1.3, 1.5)',
	'NBM': 'Melting Layer (1.8)',
	'N2M': 'Melting Layer (2.4, 2.5)',
	'N3M': 'Melting Layer (3.1, 3.4, 3.5)',
	'HHC': 'Hybrid Scan Hydrometeor Classification',
	'NRR': 'Hybrid Scan Rain Rate Classification',
	'DPR': 'Digital Inst. Precip. Rate (in/hr)',
	'OHA': 'One Hour Precipitation Accumulation',
	'DAA': 'Dig. One Hour Precip. Accum. (unbiased)',
	'DTA': 'Dig. Storm Total Precip. Accumulation',
	'DU3': 'Dig. User-Selectable Accum.: 3hr/hourly',
	'DU6': 'Dig. User-Selectable Accum.: 24hr/12Z',
	// TDWR products
	'TZL': 'Long Range Reflectivity – 225 nmi Range (0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8)',
	'TZ0': 'Base Reflectivity – 48 nmi Range (0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8)',
	'TZ1': 'Base Reflectivity – 48 nmi Range (1.0)',
	'TZ2': 'Base Reflectivity – 48 nmi Range (1.6, 1.7, 1.8, 1.9, 2.0, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 3.1, 3.3, 3.4, 3.7)',
	'TV0': 'Base Radial Velocity - 48 nmi Range (0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8)',
	'TV1': 'Base Radial Velocity - 48 nmi Range (1.0)',
	'TV2': 'Base Radial Velocity - 48 nmi Range (1.6, 1.7, 1.8, 1.9, 2.0, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 3.1, 3.3, 3.4, 3.7)',
};

// Precipitation Type (PRT) constants
const PRT_VALUE_MAX_OFFSET = 0.01;
export const PRT_VALUE_MIN = -30;
export const PRT_VALUE_MAX = 90;
export const PRT_VALUE_RANGE = PRT_VALUE_MAX-PRT_VALUE_MIN;

export const PRT_RAIN_VALUE_MIN = PRT_VALUE_MIN;
export const PRT_RAIN_VALUE_MAX = PRT_VALUE_MAX - PRT_VALUE_MAX_OFFSET;

export const PRT_SNOW_VALUE_MIN = PRT_VALUE_MAX;
export const PRT_SNOW_VALUE_MAX = PRT_SNOW_VALUE_MIN + PRT_VALUE_RANGE - PRT_VALUE_MAX_OFFSET;

export const PRT_MIXED_VALUE_MIN = PRT_SNOW_VALUE_MIN + PRT_VALUE_RANGE;
export const PRT_MIXED_VALUE_MAX = PRT_MIXED_VALUE_MIN + PRT_VALUE_RANGE - PRT_VALUE_MAX_OFFSET;




