import mapboxgl from 'mapbox-gl'

const METHOD_NAMES = [
    'asyncLoadImage',
    'asyncLoadAndAddImage',
];

for (const method of METHOD_NAMES) {
    if (method in mapboxgl.Map.prototype) {
        throw new Error(`The method '${method}' already exists on the object 'mapboxgl.Map'`);
    }
}

mapboxgl.Map.prototype.asyncLoadImage = function(path) {
    return new Promise((resolve, reject) => {
        this.loadImage(path, (error, image) => {
            if (error) {
                return reject(error);
            }
            resolve(image);
        });
    });
}

mapboxgl.Map.prototype.asyncLoadAndAddImage = async function(path, imageRef) {
    return this.addImage(imageRef, await this.asyncLoadImage(path))
}

export default mapboxgl
