import { toRaw, watch } from 'vue'
import mapboxgl from 'mapbox-gl'
import { colord } from 'colord'
import { centroid, bbox, point, bearing, destination } from '@turf/turf'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { formatTimeAgo } from '@vueuse/core'

import UrlHash from '@/tools/url-hash'
import { renderToPopup } from '@/tools/mapbox-map'
import { allSettledLimit, uniqueList } from '@/tools/helpers'
import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'
import { CARET_SVG } from '@/tools/svgs'

import radarTowers from '@/data/radar_towers.js'
import { warningConfig } from '@/data/nws_warning_config.js'

import { useWarningsStore } from '@/stores/warnings'
import { useWarningsSettingsStore } from '@/stores/settings/warnings'
import { useAlertsStore } from '@/stores/alerts';

import socket from '@/logic/Socket'
import api from '@/logic/Api'
import StormTracks from './Radar/StormTracks'
import SimpleModal from './Modals/Templates/Simple.vue'
import CenteredModal from './Modals/Templates/Centered.vue'
import WarningModal from './Warnings/Modal.vue'
import WarningNotFoundModal from './Warnings/NotFound.vue'
import WarningHelpModal from './Warnings/WarningHelp.vue'
import WarningHelpModalTitle from './Warnings/WarningHelpTitle.vue'
import SettingsModal from './Warnings/SettingsModal.vue'

export default class Warnings {
  constructor(map) {
    this.map = map

    this.warningsStore = useWarningsStore()
    this.settings = useWarningsSettingsStore();
    this.alertsStore = useAlertsStore();

    // Initialise the store
    this.warningsStore.init()

    this.mode = MODE_NONE;

    this.bufferedWarnings = [];
    this.bufferedMaxAge = 0;

    this.renderOnlyIds = [];

    this.realtimeUpdatesEnabled = true;

    this.normalSourceId = 'warnings-source'
    this.emergencySourceId = 'warnings-emergency-source'
    
    this.lineOutlineLayerId = 'warnings-outline-layer';
    this.lineLayerId = 'warnings-line-layer'
    this.emergencyLineLayerId = 'warnings-emergency-line-layer'
    this.emergencyBackgroundLineLayerId = 'warnings-emergency-background-line-layer'
    
    this.fillLayerId = 'warnings-fill-layer'
    this.emergencyFillLayerId = 'warnings-emergency-fill-layer'

    // Motion arrows
    this.motionPointsSourceId = 'warnings-motion-points-source'
    this.motionPointsLayerId = 'warnings-motion-points-layer'

    this.motionPathSourceId = 'warnings-motion-path-source'
    this.motionPathLayerId = 'warnings-motion-path-layer'

    this.motionArrowHeadSourceId = 'warnings-motion-arrow-head-source'
    this.motionArrowHeadLayerId = 'warnings-motion-arrow-head-layer'


    this.FLASH_MAX_TIME = 10 * 1000;
    this.FLASH_FREQUENCY = 750;

    this.layers = [
      this.lineOutlineLayerId,
      this.lineLayerId,
      this.emergencyLineLayerId,
      this.emergencyBackgroundLineLayerId,
      this.fillLayerId,
      this.emergencyFillLayerId,
      this.motionPointsLayerId,
      this.motionPathLayerId,
      this.motionArrowHeadLayerId
    ];

    this.addLayer()

    const warningOnClick = renderToPopup((e) => {
      const features = uniqueList(e.features, feature => feature.properties.id)
      if(features.length == 0) return;

      const container = window.document.createElement('div');

      features.forEach((feature, idx) => {
        const div = window.document.createElement('div');
        let html = `<div class='flex justify-between cursor-pointer'><div class='${idx === features.length-1 ? '' : 'mb-2'}'>`;
        html+=`<div class='font-bold'>${feature.properties.title}</div>`;
        html+=`<div>Expires ${formatTimeAgo(new Date(feature.properties.expires_at))}</div>`;
        html+=`</div><div>${CARET_SVG}</div></div>`;
        div.innerHTML = html;
        div.addEventListener('click', () => {
          this.map.popups.clear();

          this.openWarningModal(feature);
        });

        container.appendChild(div)
      });

      return container;
    });

    map.on('click', this.emergencyFillLayerId, warningOnClick)
    map.on('click', this.fillLayerId, warningOnClick)

    const dashArraySequence = [
      [0, 4, 3],
      [0.5, 4, 2.5],
      [1, 4, 2],
      [1.5, 4, 1.5],
      [2, 4, 1],
      [2.5, 4, 0.5],
      [3, 4, 0],
      [0, 0.5, 3, 3.5],
      [0, 1, 3, 3],
      [0, 1.5, 3, 2.5],
      [0, 2, 3, 2],
      [0, 2.5, 3, 1.5],
      [0, 3, 3, 1],
      [0, 3.5, 3, 0.5],
    ];

    let step = 0;
    let animateEmergenciesInterval = null;

    // Subscribe to updates from the store
    watch(() => this.warningsStore.features, () => {
      const features = toRaw(this.warningsStore.features)

      // console.log(features.length)

      this.render(features)

      // End animation
      if(animateEmergenciesInterval !== null) {
        clearInterval(animateEmergenciesInterval);
        animateEmergenciesInterval = null;
      }

      if(this.warningsStore.emergencyExists) {
        // Start animation
        animateEmergenciesInterval = setInterval(() => {
          this.map.setPaintProperty(this.emergencyLineLayerId, 'line-dasharray', dashArraySequence[step]);
          ++step;
          if(step >= dashArraySequence.length) step = 0;
        }, 50);
      }
    })

    // Render warnings already in the store (cached offline)
    this.render(toRaw(this.warningsStore.features));

    // Request latest warnings
    (async () => {
      await this.warningsStore.load();

      this.mode = MODE_REALTIME;
      
      // TODO
      // Refactor code handling params stored in the URL
      
      // Open warning from the url
      // After we've loaded the latest warnings
      const params = new UrlHash();
      if(params.has('wcid')) {
        const cid = params.get('wcid');

        const feature = this.warningsStore.features.find(f => f.properties.common_id === cid);

        if(feature !== undefined) {
          this.fitBounds(feature);

          setTimeout(() => {
            const center = centroid(feature.geometry)
            this.map.radar.turnOnClosestOnlineRadar(center);
          }, 100);

          this.openWarningModal(feature);

          return;
        }
      }

      if(params.has('wid')) {
        const id = params.get('wid');

        const feature = this.warningsStore.features.find(f => f.properties.id === id);

        if(feature !== undefined) {
          if(params.has('wr') && params.get('wr') == 1) {
            this.fitBounds(feature);
            
            setTimeout(() => {
              const center = centroid(feature.geometry);
              // Don't need to add await here
              this.map.radar.turnOnClosestOnlineRadar(center);

              const params = new UrlHash();
              params.delete('wr')
              params.save()
            }, 500); 
          }

          return this.openWarningModal(feature);
        }

        // Warning is no longer active...

        // Try and load from the archive
        await this.fetchWarningFromArchive(id)
      }
    })();
    
    // Subscribe to warnings events
    const newWarningsMap = {};

    const room = 'warnings';
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      if(! this.realtimeUpdatesEnabled) return console.log(`Incoming warning, but ignoring due to realtime updates disabled...`);

      console.log('Warnings update', room, data, `Mode: ${this.mode}`)

      if(this.mode === MODE_REALTIME) {
        const hasEndedActions = ['CAN', 'UPG'];
        if(hasEndedActions.includes(data.properties.action)) {
          // We first try delete via the ID
          // As we have a CAN for an existing warning
          if(! this.warningsStore.delete(data.properties.id)) {
            // Failing that, and this is the normal case... (using VTECs)
            // We delete via common ID
            this.warningsStore.deleteByCommonId(data.properties.common_id);
          }
        }
        else {
          // A new warning is a simple case, just append to our local list of warnings
          await this.warningsStore.push(data);

          let motion_length = 0;
          if (typeof data.properties.tags === 'object' && typeof data.properties.tags.TIME_MOT_LOC === 'object') {
            motion_length = data.properties.tags.TIME_MOT_LOC.positions.length;
          }

          newWarningsMap[data.properties.id] = {
            at: new Date(),
            emergency: data.properties.emergency,
            motion_length,
            flash: true
          };
        }
      }
      else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
        this.bufferedWarnings.push(data);

        // Remove expired warnings
        const cutOff = moment.utc();
        cutOff.subtract(this.bufferedMaxAge, 'seconds');

        this.bufferedWarnings = this.bufferedWarnings.filter(function(f){
          const expiresAt = moment.utc(f.properties.expires_at);

          return ! expiresAt.isBefore(cutOff);
        });
      }
    })

    // newWarningsMap['c5b1b516fed1ecb8056c890143ae9eb3'] = {
    //   at: new Date(),
    //   emergency: false,
    //   motion_length: 1,
    //   flash: true
    // }

    setInterval(() => {
      // console.log(newWarningsMap);

      const now = new Date();
      for(const warningId in newWarningsMap) {
        const w = newWarningsMap[warningId];

        const age = now.getTime() - w.at.getTime();
        const source = w.emergency === true ? this.emergencySourceId : this.normalSourceId;

        if(age > this.FLASH_MAX_TIME) {
          // Set the feature to not flash
          this.map.setFeatureState({ source, id: warningId }, { flash: false });

          for(let i = 0; i < w.motion_length; ++i) {
            this.map.setFeatureState({ source: this.motionPointsSourceId, id: `${warningId}-${i}` }, { flash: false });
            this.map.setFeatureState({ source: this.motionPathSourceId, id: `${warningId}-${i}` }, { flash: false });
            this.map.setFeatureState({ source: this.motionArrowHeadSourceId, id: `${warningId}-${i}` }, { flash: false });
          }

          // Delete from map
          delete newWarningsMap[warningId];

          // Continue with the rest
          continue;
        }

        this.map.setFeatureState({ source, id: warningId }, { flash: w.flash });

        for(let i = 0; i < w.motion_length; ++i) {
          this.map.setFeatureState({ source: this.motionPointsSourceId, id: `${warningId}-${i}` }, { flash: w.flash });
          this.map.setFeatureState({ source: this.motionPathSourceId, id: `${warningId}-${i}` }, { flash: w.flash });
          this.map.setFeatureState({ source: this.motionArrowHeadSourceId, id: `${warningId}-${i}` }, { flash: w.flash });
        }

        w.flash = !w.flash;
      }
    }, this.FLASH_FREQUENCY);

    const manageVisible = (state) => {
      if(state.visible) {
        this.show();
      }
      else {
        this.hide();
      }
    }

    this.settings.$subscribe((mutation, state) => {
      manageVisible(state)
    });

    manageVisible(this.settings);
  }

  flashFeature(feature) {
    const source = feature.properties.emergency === true ? this.emergencySourceId : this.normalSourceId;

    let motionLength = 0;
    if (typeof feature.properties.tags === 'object' && typeof feature.properties.tags.TIME_MOT_LOC === 'object') {
      motionLength = feature.properties.tags.TIME_MOT_LOC.positions.length;
    }

    this.setWarningFlash(source, feature.properties.id, motionLength, true);

    setTimeout(() => {
      this.setWarningFlash(source, feature.properties.id, motionLength, false);
    }, this.FLASH_FREQUENCY/1.5)
  }

  setWarningFlash(source, warningId, motionLength, flash) {
    this.map.setFeatureState({ source, id: warningId }, { flash });

    for(let i = 0; i < motionLength; ++i) {
      this.map.setFeatureState({ source: this.motionPointsSourceId, id: `${warningId}-${i}` }, { flash });
      this.map.setFeatureState({ source: this.motionPathSourceId, id: `${warningId}-${i}` }, { flash });
      this.map.setFeatureState({ source: this.motionArrowHeadSourceId, id: `${warningId}-${i}` }, { flash });
    }
  }

  addLayer() {
    this.map.addSource(this.normalSourceId, {
      type: 'geojson',
      // This will us the id from the 'properties' as the feature ID
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addSource(this.emergencySourceId, {
      type: 'geojson',
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addLayer({
      id: this.emergencyLineLayerId,
      type: 'line',
      source: this.emergencySourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          '#FFFFFF',
          ['get', 'line-color']
        ],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          ['*', ['get', 'line-width'], 1.75],
          ['*', ['get', 'line-width'], 1.5]
        ],
        'line-dasharray': [0, 4, 3]
      }
    }, 'top-middle-radar-layer')

    this.map.addLayer({
      id: this.emergencyBackgroundLineLayerId,
      type: 'line',
      source: this.emergencySourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-background-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          ['*', ['get', 'line-width'], 1.75],
          ['get', 'line-width']
        ]
      }
    }, this.emergencyLineLayerId)

    this.map.addLayer({
      id: this.emergencyFillLayerId,
      type: 'fill',
      source: this.emergencySourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
      }
    }, 'bottom-middle-radar-layer')

    this.map.addLayer({
      id: this.lineLayerId,
      type: 'line',
      source: this.normalSourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          '#FFFFFF',
          ['get', 'line-color']
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          1,
          ['get', 'line-opacity']
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          ['*', ['get', 'line-width'], 1.75],
          ['get', 'line-width']
        ]
      }
    }, this.emergencyLineLayerId)

    this.map.addLayer({
      id: this.lineOutlineLayerId,
      type: 'line',
      source: this.normalSourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          '#000000',
          '#FFFFFF'
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          1,
          ['get', 'line-opacity']
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          ['+', ['*', ['get', 'line-width'], 1.75], 1],
          ['+', ['get', 'line-width'], 1]
        ]
      }
    }, this.lineLayerId);

    this.map.addLayer({
      id: this.fillLayerId,
      type: 'fill',
      source: this.normalSourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
      }
    }, 'bottom-middle-radar-layer')

    // Add source/layer for motion points
    map.addSource(this.motionPointsSourceId, {
      type: 'geojson',
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer({
      id: this.motionPointsLayerId,
      type: 'circle',
      source: this.motionPointsSourceId,
      paint: {
        'circle-radius': 3,
        'circle-stroke-width': 1,
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          '#FFFFFF',
          ['get', 'circle-color']
        ],
        'circle-stroke-color': ['get', 'circle-stroke-color']
      },
      minzoom: 6
    })

    // Add source/layer for motion path
    map.addSource(this.motionPathSourceId, {
      type: 'geojson',
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer(
      {
        id: this.motionPathLayerId,
        type: 'line',
        source: this.motionPathSourceId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'flash'], false],
            '#FFFFFF',
            ['get', 'line-color']
          ],
          'line-width': 2
        },
        minzoom: 6
      },
      this.motionPointsLayerId
    )

    // Add source/layer for motion arrow head
    map.addSource(this.motionArrowHeadSourceId, {
      type: 'geojson',
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer(
      {
        id: this.motionArrowHeadLayerId,
        type: 'fill',
        source: this.motionArrowHeadSourceId,
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'flash'], false],
            '#FFFFFF',
            ['get', 'fill-color']
          ]
        },
        minzoom: 6
      },
      this.motionPointsLayerId
    )
  }

  openWarningHelpModal(type) {
    const config = warningConfig[type]

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: config.name
      },
      slots: {
        title: useModalSlot({
          component: WarningHelpModalTitle,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        }),
        default: useModalSlot({
          component: WarningHelpModal,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openWarningModal(feature) {
    const config = warningConfig[`${feature.properties.product}.${feature.properties.significance}`]

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: config.name,
        onOpened() {
          const params = new UrlHash()
          params.set('wid', feature.properties.id)
          params.save()
        },
        onClosed() {
          const params = new UrlHash()
          params.delete('wid')
          params.save()
        },
      },
      slots: {
        default: useModalSlot({
          component: WarningModal,
          attrs: {
            feature: feature,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openSettings() {
    const me = this;
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Warning Settings',
        onClose() {
          me.alertsStore.setSelectedAlert(null);
        },
      },
      slots: {
        default: useModalSlot({
          component: SettingsModal,
        })
      },
    })

    return modal;
  }

  openNotFoundModal() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Warning Not Found',
      },
      slots: {
        default: useModalSlot({
          component: WarningNotFoundModal
        })
      },
    })
  }

  applyPropertiesToFeature(f) {
    const key = `${f.properties.product}.${f.properties.significance}`;
    const config = warningConfig[key];

    const settingConfig = toRaw(this.settings.config[f.properties.country_iso][key]);

    f.properties['line-color'] = f.properties.emergency ? '#FFFFFF' : settingConfig.color;
    f.properties['line-background-color'] = settingConfig.color;
    f.properties['line-opacity'] = 0.7;
    f.properties['line-width'] = f.properties.emergency ? 4 : settingConfig.line_width;
    f.properties['line-sort-key'] = 1000 - config.priority;

    f.properties['fill-color'] = settingConfig.color;
    f.properties['fill-opacity'] = settingConfig.fill_opacity;
    f.properties['fill-sort-key'] = 1000 - config.priority;
    
    return f
  }

  render(rawFeatures) {
    const features = rawFeatures.filter((f) => {
      const config = warningConfig[`${f.properties.product}.${f.properties.significance}`]

      if (config === undefined) {
        console.log(`NWS config missing for: ${f.properties.product}.${f.properties.significance}`)
      }

      return config !== undefined;
    });

    const normalFeatures = features.filter(f => !f.properties.emergency);
    const emergencyFeature = features.filter(f => f.properties.emergency);

    this.map.getSource(this.normalSourceId).setData({
      type: 'FeatureCollection',
      features: normalFeatures
    })

    this.map.getSource(this.emergencySourceId).setData({
      type: 'FeatureCollection',
      features: emergencyFeature
    })

    this.renderMotion(features)
  }

  renderOnly(features) {
    this.renderOnlyIds = features.map(f => f.properties.id);

    this.render(features)
  }

  renderMotion(features) {
    const pointCollection = {
      type: 'FeatureCollection',
      features: []
    }

    const lineCollection = {
      type: 'FeatureCollection',
      features: []
    }

    const arrowHeadCollection = {
      type: 'FeatureCollection',
      features: []
    }

    const now = moment.utc()

    features.forEach((f) => {
      try {
        if (
          !(
            typeof f.properties.tags === 'object' &&
            typeof f.properties.tags.TIME_MOT_LOC === 'object'
          )
        )
          return

        const motion = f.properties.tags.TIME_MOT_LOC
        const lineColor = f.properties['line-background-color']
        const darkenedColor = colord(lineColor).darken(0.25).toHex()

        // console.log(motion)

        if (motion.speed < 1) return

        motion.positions.forEach((p, i) => {
          // Points
          pointCollection.features.push({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: p },
            properties: {
              id: `${f.properties.id}-${i}`,
              'circle-color': lineColor,
              'circle-stroke-color': darkenedColor
            }
          })

          // Lines
          const po = point(p)
          const direction = (motion.direction + 180) % 360
          // Convert knots to mph
          const speedMph = motion.speed * 1.15078

          const age = moment.duration(now.diff(moment.utc(f.properties.issued_at))).asHours()

          let distance = age * speedMph

          // Have a cut off for distance?
          if (distance > 10) {
            distance = 10
          }
          else if(distance < 3) {
            distance = 3;
          }

          const dest = destination(po, distance, direction, { units: 'miles' })

          const lineCoords = [p, dest.geometry.coordinates]

          lineCollection.features.push({
            type: 'Feature',
            geometry: { type: 'LineString', coordinates: lineCoords },
            properties: {
              id: `${f.properties.id}-${i}`,
              'line-color': lineColor
            }
          })

          // Arrow heads
          const size = 0.5;
          const sideSize = 0.33;
          const startOfArrowHead = destination(po, distance - size, direction, { units: 'miles' });
          const leftSide = destination(startOfArrowHead, sideSize, direction-90, { units: 'miles' }).geometry.coordinates;
          const rightSide = destination(startOfArrowHead, sideSize, direction+90, { units: 'miles' }).geometry.coordinates;
          const arrowHeadCoords = [dest.geometry.coordinates, leftSide, rightSide, dest.geometry.coordinates];

          arrowHeadCollection.features.push({
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [arrowHeadCoords] },
            properties: {
              id: `${f.properties.id}-${i}`,
              'fill-color': lineColor
            }
          })
        })
      } catch (e) {
        console.error('Failed to generate warning motion', f, e)
      }
    })

    this.map.getSource(this.motionPointsSourceId).setData(pointCollection)
    this.map.getSource(this.motionPathSourceId).setData(lineCollection)
    this.map.getSource(this.motionArrowHeadSourceId).setData(arrowHeadCollection)
  }

  async loadHistory(secsToLoad) {
    this.mode = MODE_HISTORICAL_TO_REALTIME;

    this.bufferedWarnings = [];
    this.bufferedMaxAge = secsToLoad;

    try {
      const geojson = await api.instance().get(`/warnings/USA-${secsToLoad}.geojson`);

      const nonGeoJsonFeatures = geojson.features.filter(f => f.geometry === null);

      const processNonGeoJsonFeature = (feature) => {
        return async () => {
          feature.geometry = await this.warningsStore.fetchGeometry(feature);
        }
      };

      if(nonGeoJsonFeatures.length > 0) {
        const tasks = nonGeoJsonFeatures.map(feature => processNonGeoJsonFeature(feature));
          
        console.time("Load warning geometry data for playback");
        await allSettledLimit(tasks, 6);
        console.timeEnd("Load warning geometry data for playback");
      }

      this.bufferedWarnings = geojson.features;

      // console.log(this.bufferedWarnings)
    } catch (error) {
      console.log('Failed to load warnings archive list', error);
    }
  }

  drawHistory(dt, displayFuture) {
    if(typeof dt === 'string') {
      dt = moment.utc(dt);
    }

    // Assume that the historical warnings are ordered by issued datetime

    // First we'll filter the warning that are applicable
    // ie the issued at is before dt
    // the dt is before the expires at

    const latestAction = {};
    const latestWarningIssuedAt = {};

    let filterFn = displayFuture ? (f) => {
      if(this.renderOnlyIds.length > 0) {
        if(! this.renderOnlyIds.includes(f.properties.id)) return false;
      }

      const expiresAt = moment.utc(f.properties.expires_at);

      const keep = dt.isBefore(expiresAt)

      if(keep) {
        latestAction[f.properties.common_id] = f.properties.action;
        latestWarningIssuedAt[f.properties.common_id] = f.properties.issued_at;
      }

      return keep;
    } : (f) => {
      if(this.renderOnlyIds.length > 0) {
        if(! this.renderOnlyIds.includes(f.properties.id)) return false;
      }

      const issuedAt = moment.utc(f.properties.issued_at);
      const expiresAt = moment.utc(f.properties.expires_at);

      const keep = issuedAt.isBefore(dt) && dt.isBefore(expiresAt);

      if(keep) {
        latestAction[f.properties.common_id] = f.properties.action;
        latestWarningIssuedAt[f.properties.common_id] = f.properties.issued_at;
      }

      return keep;
    };

    const filter1 = this.bufferedWarnings.filter(filterFn);

    // console.log(latestAction)
    // console.log(latestWarningIssuedAt)

    const hasEndedActions = ['CAN', 'UPG'];

    const filtered = filter1.filter(f => {
      // We're going to filter out warning where the latest action is cancelled
      // We're going to filter out warnings where it's the 'same' warning but not the latest
      return ! hasEndedActions.includes(latestAction[f.properties.common_id]) &&
              latestWarningIssuedAt[f.properties.common_id] === f.properties.issued_at;
    });

    this.warningsStore.setFeatureList(filtered);
  }

  clearBufferedHistoricalState() {
    this.bufferedWarnings = [];

    if(this.mode !== MODE_REALTIME) {
      this.warningsStore.load();
    }

    this.mode = MODE_REALTIME;
  }

  async fetchWarningFromArchive(id) {
    try {
      const geojson = await api.instance().get(`/warnings/archive/${id}.geojson`);
      const feature = this.applyPropertiesToFeature(geojson);

      return this.openWarningModal(feature);
    } catch (error) {
      console.log(error)
      
      this.openNotFoundModal();
    }
  }

  show() {
    for(const layerId of this.layers) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of this.layers) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  setRealtimeUpdates(b) {
    this.realtimeUpdatesEnabled = b;
  }

  fitBounds(feature) {
    const box = bbox(feature.geometry)

    const sw = new mapboxgl.LngLat(box[0], box[1]);
    const ne = new mapboxgl.LngLat(box[2], box[3]);
    const llb = new mapboxgl.LngLatBounds(sw, ne);

    // Include the motion points in the bounding box
    // As sometimes they can be a little far outside of the polygon
    if(feature.properties.tags?.TIME_MOT_LOC !== undefined) {
      feature.properties.tags?.TIME_MOT_LOC?.positions?.forEach(p => {
        llb.extend(p);
      });
    }

    this.map.fitBounds(llb, {
      padding: window.innerWidth / 8,
      duration: 0
    })
  }

  getMode() {
    return this.mode;
  }
}
