import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import axiosRetry, { type IAxiosRetryConfig } from 'axios-retry'

const API_SERVERS = [
    'https://data2.weatherwise.app',
    'https://data1.weatherwise.app',
]

// The default length of time a request will wait for a response
const DEFAULT_TIMEOUT = 15000
// The max amount of time a request will wait for a response
const MAX_TIMEOUT = 60000
// The max amount of time to delay between failed/retrying requests
const MAX_WAIT_TIMEOUT = 60000

interface HttpClient {
    get: <T>(url: string, options?: AxiosRequestConfig) => Promise<T>
    post: <T>(url: string, data?: unknown, options?: AxiosRequestConfig) => Promise<T>
    setServer: (server: string) => void
    getCurrentServer: () => string
}

interface ServerConfig {
    urls: string[]
    timeout: number
    axiosConfig?: AxiosRequestConfig
    maxRetryAttempts: number
}

const DEFAULT_CONFIG: ServerConfig = {
    urls: API_SERVERS,
    timeout: DEFAULT_TIMEOUT,
    axiosConfig: {
        adapter: 'fetch'
    },
    maxRetryAttempts: API_SERVERS.length * 2,
}

export const createHttpClient = (
    initialConfig?: Partial<ServerConfig>
): HttpClient => {
    const config = {
        ...DEFAULT_CONFIG,
        ...initialConfig,
        axiosConfig: {
            ...DEFAULT_CONFIG.axiosConfig,
            ...initialConfig?.axiosConfig
        },
    }
    let currentServerIdx = 0
    let axiosInstance: AxiosInstance

    const createHttpInstance = (server: string, configOverride?: AxiosRequestConfig): AxiosInstance => {
        const instance = axios.create({
            baseURL: server,
            timeout: config.timeout,
            ...config.axiosConfig,
            ...configOverride
        })

        // Configure axios-retry
        axiosRetry(instance, {
            retries: config.maxRetryAttempts,
            retryDelay: (retryCount) => {
                return Math.min(
                    MAX_WAIT_TIMEOUT,
                    Math.imul(1000, Math.pow(2, retryCount - 1))
                )
            },
            onRetry: (retryCount, error, requestConfig) => {
                // This is to force the usage of the baseURL. It ensures the url given is relative.
                try {
                    const url = new URL(requestConfig.url as string)
                    requestConfig.url = requestConfig.url.replace(`${url.protocol}//${url.host}`, '')
                } catch (error) {
                    if (! error.message.includes('Invalid URL')) {
                        throw error
                    }
                }
                const newServer = switchServer(API_SERVERS.indexOf(requestConfig.baseURL as string))
                requestConfig.baseURL = newServer
                requestConfig.timeout = getHttpAttemptsTimeout(retryCount)
                axiosInstance = createHttpInstance(newServer)
            }
        } as IAxiosRetryConfig)

        return instance
    }

    const getCurrentServer = (): string => config.urls[currentServerIdx]

    // Initialize axiosInstance with first server
    axiosInstance = createHttpInstance(getCurrentServer())

    const switchServer = (attemptingServerIdx: number): string => {
        currentServerIdx = (attemptingServerIdx + 1) % config.urls.length
        return getCurrentServer()
    }

    const get = async <T>(url: string, options?: AxiosRequestConfig): Promise<T> => {
        const response = await axiosInstance.get<T>(url, options)
        return response.data
    }

    const post = async <T>(url: string, data?: unknown, options?: AxiosRequestConfig): Promise<T> => {
        const response = await axiosInstance.post<T>(url, data, options)
        return response.data
    }

    const setServer = (server: string): void => {
        currentServerIdx = config.urls.indexOf(server)
        axiosInstance = createHttpInstance(server)
    }

    const getHttpAttemptsTimeout = (attempts: number): number => {
        const backoffTimeout = (config.timeout as number) * Math.pow(2, attempts)
        return Math.min(backoffTimeout, MAX_TIMEOUT)
    }

    return {
        get,
        post,
        setServer,
        getCurrentServer,
    }
}