<script setup lang="ts">
import { useMapSettingsStore } from '@/stores/settings/map';
import { useMapStyle } from '@/logic/Composables/MapStyle'

const mapSettingsStore = useMapSettingsStore();

const useSatelliteStyle = () => {
    mapSettingsStore.setSatelliteMapStyle()
    useMapStyle().satelliteTiles()
}

const useDefaultStyle = () => {
    mapSettingsStore.setDefaultMapStyle()
    useMapStyle().defaultTiles()
}
</script>

<template>
    <div class="mt-6 dark:text-slate-300">
        <h3 class="text-xl font-bold text-gray-800 mb-4">Style</h3>
        <div class="flex gap-10 justify-center">
            <div>
                <button @click.prevent="useDefaultStyle">
                    <img src="@/assets/map-tiles/default-tile.png" class="w-24 md:w-32 h-auto rounded-lg" :class="{
                        'border-sky-400 border-4': mapSettingsStore.tileStyle === 'default'
                    }" />
                    <div class="text-sm">Default</div>
                </button>
            </div>
            <div>
                <button @click.prevent="useSatelliteStyle">
                    <img src="@/assets/map-tiles/satellite-tile.png" class="w-24 md:w-32 h-auto rounded-lg" :class="{
                        'border-sky-400 border-4': mapSettingsStore.tileStyle === 'satellite'
                    }" />
                    <div class="text-sm">Satellite</div>
                </button>
            </div>
        </div>
    </div>
</template>