<template>
  <div class="mb-4">
    <p class="text-gray-900 dark:text-slate-300">We wish to say a special thank you to everyone that provides us with the data that powers WeatherWise <a href="#" @click.prevent="startDebug">❤️</a></p>
  </div>
  <div class="mb-4">
    <h4 class="font-bold text-gray-900 dark:text-slate-300">Canada</h4>
    <p class="text-gray-800 dark:text-slate-300">Data Source: <a href="https://www.canada.ca/en/environment-climate-change.html" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">Environment and Climate Change Canada (ECCC)</a></p>
  </div>
  <div class="mb-4">
    <h4 class="font-bold text-gray-900 dark:text-slate-300">Colombia</h4>
    <p class="text-gray-800 dark:text-slate-300">Data Source: <a href="https://www.ideam.gov.co/" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">Institute of Hydrology, Meteorology and Environmental Studies (IDEAM)</a></p>
  </div>
  <div class="mb-4">
    <h4 class="font-bold text-gray-900 dark:text-slate-300">Estonia</h4>
    <p class="text-gray-800 dark:text-slate-300">Data Source: <a href="https://keskkonnaagentuur.ee/en" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">Republic of Estonia Environment Agency</a></p>
  </div>
  <div class="mb-4">
    <h4 class="font-bold text-gray-900 dark:text-slate-300">Finland</h4>
    <p class="text-gray-800 dark:text-slate-300">Data Source: <a href="https://en.ilmatieteenlaitos.fi/" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">Finnish Meteorological Institute</a></p>
  </div>
  <div class="mb-4">
    <h4 class="font-bold text-gray-900 dark:text-slate-300">Iceland</h4>
    <p class="text-gray-800 dark:text-slate-300">Data Source: <a href="https://en.vedur.is/" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">Icelandic Meteorological Office</a></p>
  </div>
  <div class="mb-4">
    <h4 class="font-bold text-gray-900 dark:text-slate-300">Ireland</h4>
    <p class="text-gray-800 dark:text-slate-300">Data Source: <a href="https://www.met.ie/" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">The Irish Meteorological Service</a></p>
  </div>
  <div>
    <h4 class="font-bold">United States of America</h4>
    <p>Data Source: <a href="https://www.noaa.gov/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">National Oceanic and Atmospheric Administration (NOAA)</a>, <a href="https://drought.unl.edu/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">National Drought Mitigation Center (NDMC)</a>, <a href="https://www.usda.gov/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">U.S. Department of Agriculture (USDA)</a>, <a href="https://www.nasa.gov/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">National Aeronautics and Space Administration (NASA)</a></p>
  </div>
</template>

<script>
export default {
  methods: {
    startDebug() {
      (function(){var s=document.createElement("script");s.src="https://remotejs.com/agent/agent.js";s.setAttribute("data-consolejs-channel","2cd66bb4-932b-c56d-b812-a2f2971b7ec9");document.head.appendChild(s);})()
    }
  }
}
</script>