import { render, h, ref, inject } from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useHazcamsStore } from '@/stores/settings/vendors/hazcams'

import UrlHash from '@/tools/url-hash'
import { renderToPopup } from '@/tools/mapbox-map'
import { allSettledLimit } from '@/tools/helpers'
import api from '@/logic/Api'
import socket from '@/logic/Socket'

import VideoModal from './Modals/Templates/Video.vue'
import HazcamPlaybackModal from './Hazcams/PlaybackModal.vue'
import HazcamsPopup from './Hazcams/HazcamsPopup.vue'

class Hazcams {
  constructor() {
    this.map = null
    this.sourceId = 'hazcams-source'
    this.pointLayerId = 'hazcams-point-layer'
    this.boundPointOnClick = null
    this.stations = {
      type: 'FeatureCollection',
      features: []
    };

    this.pointOnClick = renderToPopup((e) => {
      if (e.features.length === 0) return;

      const container = window.document.createElement('div');

      e.features.forEach((feature, idx) => {
        const popupContainer = window.document.createElement('div');

        render(h(HazcamsPopup, {
          feature,
          isLast: idx === e.features.length - 1,
          onClick: () => {
            this.map?.popups.clear();
            if (navigator.userAgent.includes('WeatherWise/') && navigator.userAgent.includes('Apple')) {
              window.open(`https://web2.weatherwise.app/hazcams/${feature.properties.id}`, '_blank')
            } else {
              this.openPlaybackModal(feature);
            }
          }
        }), popupContainer);

        container.appendChild(popupContainer);
      });

      return container;
    });
  }

  async draw(map) {
    if (!map) {
      throw new Error('Map instance is required')
    }

    try {
      this.map = map

      const tasks = [];
      tasks.push(async () => {
        await this.map.asyncLoadAndAddImage(new URL('../assets/vendors/hazcams/hazcam-point.png', import.meta.url).href, 'hazcams-dot')
      })

      tasks.push(async () => {
        await this.map.asyncLoadAndAddImage(new URL('../assets/vendors/hazcams/hazcam-mobile-point.png', import.meta.url).href, 'hazcams-mobile-dot')
      });

      tasks.push(async () => {
        await this.map.asyncLoadAndAddImage(new URL('../assets/vendors/hazcams/brett-profile-pic.png', import.meta.url).href, 'hazcams-brett-adair')
      });

      await allSettledLimit(tasks, 6);

      this.addLayer()
      await this.addData()

      this.boundPointOnClick = this.pointOnClick.bind(this)
      this.map.on('click', this.pointLayerId, this.boundPointOnClick)
    } catch(e) {
      // Cleanup any partial state if drawing fails
      this.clear()
      throw new Error(`Failed to draw hazcams: ${e.message}`)
    }
  }

  addLayer() {
    this.map.addSource(this.sourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    this.map.addLayer({
      'id': this.pointLayerId,
      'type': 'symbol',
      'source': this.sourceId,
      'layout': {
        'icon-image': ['get', 'icon-image'],
        'icon-size': 0.15,
        'icon-allow-overlap': false,
        'icon-anchor': 'center',
        'icon-offset': [0, 0],
        'symbol-sort-key': ["get", "symbol-sort-key"]
      }
    }, 'radar-towers-layer');
  }

  async fetchAndSetData() {
    const stationsCopy = JSON.parse(JSON.stringify(this.stations));

    await this.fetchData();
    this.map.getSource(this.sourceId).setData(this.stations);

    const params = new UrlHash();
    if(params.has('hctrackid')) {
      const hcId = params.get('hctrackid');

      const currentStation = this.stations.features.find(f => f.properties.id === hcId);

      if(currentStation === undefined) return;

      const previousStation = stationsCopy.features.find(f => f.properties.id === hcId);

      if(previousStation === undefined || (previousStation !== undefined && JSON.stringify(currentStation.geometry) !== JSON.stringify(previousStation.geometry))) {
        const location = currentStation.geometry.coordinates;

        window.map.easeTo({
          center: location,
          duration: 1000
        });

        setTimeout(() => {
          window.map.radar.turnOnClosestRadar(location);
        }, 1010);
      }
    }
  }

  async addData() {
    try {
      await this.fetchAndSetData();

      const room = 'vendors:hazcams:stations'
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('Hazcams update', room, data);

        try {
          await this.fetchAndSetData();
        } catch(e) {
          console.error('Failed to load hazcams data after update', e)
        }
      });
    } catch(e) {
      console.error('Failed to load hazcams data', e)
    }
  }

  async fetchData() {
    this.stations = await api.instance().get(`/vendors/hazcams/stations.geojson`)

    this.stations.features = this.stations.features.filter(station => station.properties.online && !station.properties.unlisted).map(f => {
      let iconImage = 'hazcams-dot';
      let priority = 3;

      if(f.properties.id.startsWith('hazcams-mobile-')) {
        iconImage = 'hazcams-mobile-dot';
        priority = 2;
      }
      else {
        if(f.properties.id === 'eb7ded21-88bd-4dd2-a5f9-a06d964ec4a7') {
          f.properties.name = 'Brett Adair';
          iconImage = 'hazcams-brett-adair';
          priority = 1;
        }
      }

      // console.log(iconImage, priority, f.geometry)

      f.properties['icon-image'] = iconImage;
      f.properties['symbol-sort-key'] = priority;

      return f;
    });
  }

  openPlaybackModal(feature) {
    useModal({
      defaultModelValue: true,
      component: VideoModal,
      attrs: {
        title: `${feature.properties.name}`,
        onOpened() {
          const params = new UrlHash();
          params.set('hcid', feature.properties.id)
          params.save()
        },
        onClosed() {
          const params = new UrlHash();
          params.delete('hcid')
          params.save()
        },
      },
      slots: {
        default: useModalSlot({
          component: HazcamPlaybackModal,
          attrs: {
            feature: feature,
          }
        })
      },
    });
  }

  show() {
    if (!this.map) return;
    for(const layerId of [this.pointLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    if (!this.map) return;

    for(const layerId of [this.pointLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  clear() {
    if (!this.map) return

    try {
      this.map.popups.clear()

      if (this.boundPointOnClick) {
        this.map.off('click', this.pointLayerId, this.boundPointOnClick)
        this.boundPointOnClick = null
      }

      if (this.map.hasImage('hazcams-dot')) {
        this.map.removeImage('hazcams-dot')
      }
      if (this.map.hasImage('hazcams-mobile-dot')) {
        this.map.removeImage('hazcams-mobile-dot')
      }
      if (this.map.hasImage('hazcams-brett-adair')) {
        this.map.removeImage('hazcams-brett-adair')
      }

      // Check if layers/source exist before removing
      if (this.map.getLayer(this.pointLayerId)) {
        this.map.removeLayer(this.pointLayerId)
      }

      if (this.map.getSource(this.sourceId)) {
        this.map.removeSource(this.sourceId)
      }

      const room = 'vendors:hazcams:stations'
      socket.roomLeave(room)
      socket.removeAllListeners(room)

    } catch(e) {
      throw new Error(`Failed to clear hazcams: ${e.message}`)
    }
  }

  getStations() {
    return this.stations.features
  }
}

const hazcams = new Hazcams()

const createHazcams = {
  async install(app) {
    const init = async () => {
      console.log('Installing hazcams')

      const urlHash = new UrlHash()

      if (urlHash.has('hcid')) {
        await hazcams.fetchData()
        const feature = hazcams.getStations().find(station => station.properties.id === urlHash.get('hcid'))
        if (feature) {
          hazcams.openPlaybackModal(feature)
        }
      }

      console.log('Hazcams initialized')
    }

    // Start initialization
    try {
      await init()
    } catch(e) {
      console.error('Failed to initialize hazcams:', e)
    }
  }
}

const useHazcams = () => {

  const toggle = async () => {
    try {
      if (useHazcamsStore().mapLayerOn) {
        await hide()
      } else {
        await show()
      }
    } catch(e) {
      console.error('Failed to toggle hazcams:', e)
      throw new Error('Failed to toggle hazcam visibility')
    }
  }

  const show = async () => {
    await hazcams.draw(window.map)
    useHazcamsStore().set(true)
  }

  const hide = async () => {
    hazcams.clear()
    useHazcamsStore().set(false)
  }

  const update = async () => {
    if (! isVisible()) {
      return;
    }
    try {
      await hazcams.fetchAndSetData()
    } catch (e) {
      console.log(`Failed to update hazcams`, e)
    }
  }

  const isVisible = () => useHazcamsStore().mapLayerOn

  const showIfVisible = () => {
    if (isVisible()) {
      show()
    }
  }

  return {
    toggle,
    show,
    hide,
    isVisible,
    showIfVisible,
    hazcams,
    update
  }
}

export { useHazcams, createHazcams }
