export const useMapStyle = () => {
    const satelliteTileId = 'satellite-tiles'
    const satelliteLayerBefore = 'top-middle-satellite-layer'

    const hiddenSatelliteLayers = [
        'building',
        'building-outline',
    ];

    const defaultTiles = () => {
        if(window.map.getLayer(satelliteTileId) === undefined) return;
        
        window.map.removeLayer(satelliteTileId);
        window.map.removeSource(satelliteTileId);

        hiddenSatelliteLayers.forEach(layerId => window.map.setLayoutProperty(layerId, 'visibility', 'visible'));
    }

    const satelliteTiles = () => {
        window.map.addLayer({
            id: satelliteTileId,
            source: { "type": "raster", "url": "mapbox://mapbox.satellite", "tileSize": 256 },
            type: "raster"
        }, satelliteLayerBefore);

        hiddenSatelliteLayers.forEach(layerId => window.map.setLayoutProperty(layerId, 'visibility', 'none'));
    }

    return {
        defaultTiles,
        satelliteTiles
    }
}