const HEADER_VERSION = 1;
const HEADER_V1_SIZE = 68; // bytes
const HEADER_V1_HEADER_DATA_TYPE = 0; // JSON

export default class Packet {
    constructor(buffer) {
        this.buffer = buffer;
    }

    isBinary() {
        if (this.buffer.byteLength >= HEADER_V1_SIZE) {
            const uint8View = new Uint8Array(this.buffer, 0, 4);

            if (uint8View[0] === 87 && uint8View[1] === 73 && uint8View[2] === 83 && uint8View[3] === 69) {
                return true;
            }
        }

        return false;
    }

    header() {
        const dataView = new DataView(this.buffer);
        let offset = 0;

        // Helper function to read strings
        function readString(length) {
            let chars = [];
            for (let i = 0; i < length; i++) {
                chars.push(String.fromCharCode(dataView.getUint8(offset++)));
            }
            return chars.join('');
        }

        // Helper functions to read different data types
        function readUint8() {
            const value = dataView.getUint8(offset);
            offset += 1;
            return value;
        }

        function readUint32() {
            const value = dataView.getUint32(offset, false); // Big-endian
            offset += 4;
            return value;
        }

        function readFloat32() {
            const value = dataView.getFloat32(offset, false); // Big-endian
            offset += 4;
            return value;
        }

        // Unpack each field according to the format
        const magic = readString(4); // 4s
        const version = readUint8(); // B

        if (version !== HEADER_VERSION) {
            throw new Error(`Unsupported WISE bin. format version: ${version}`);
        }

        const flags = readUint8(); // B
        const header_data_type = readUint8(); // B
        const padding1 = readUint8(); // B
        const header_data_length = readUint32(); // I
        const payload_length = readUint32(); // I
        const payload_bits = readUint8(); // B
        const padding2 = readUint8(); // B
        const payload_range_min = readFloat32(); // f
        const payload_range_max = readFloat32(); // f
        const dim_x = readUint32(); // I
        const dim_y = readUint32(); // I
        const dim_z = readUint32(); // I

        // Read reserved values
        for (let i = 0; i < 7; ++i) {
            readUint32();
        }
        for (let i = 0; i < 2; ++i) {
            readUint8();
        }

        // console.log(magic, version, flags, header_data_type, padding1);
        // console.log(header_data_length, payload_length, payload_bits, padding2);
        // console.log(payload_range_min, payload_range_max, dim_x, dim_y, dim_z);

        return {
            magic, // 4-byte string
            version, // Unsigned char
            flags,
            header_length: HEADER_V1_SIZE,
            header_data_type,
            header_data_length, // Unsigned int
            payload_length, // Unsigned int
            flags, // Unsigned char
            payload_bits, // Byte
            payload_range_min, // Float
            payload_range_max, // Float
            dim_x, // Unsigned int
            dim_y, // Unsigned int
            dim_z, // Unsigned int
        };
    }

    headerData(header) {
        const dataView = new DataView(this.buffer);

        if (header.header_data_type === HEADER_V1_HEADER_DATA_TYPE) {
            // Create a Uint8Array view of the specific bytes
            const bytes = new Uint8Array(dataView.buffer, HEADER_V1_SIZE, header.header_data_length);

            // Initialize the TextDecoder with the specified encoding
            const decoder = new TextDecoder('utf-8');

            // Decode and return the string
            // Ensure we trim empty spaces (padding) before we JSON.parse
            const header_data_raw = decoder.decode(bytes).trim();

            const header_data = JSON.parse(header_data_raw)

            // Now attach extra properties to the header data
            header_data.dims = [header.dim_x, header.dim_y, header.dim_z];
            header_data.range = {
                min: header.payload_range_min,
                max: header.payload_range_max
            }
            header_data.precision = header.payload_bits;

            return header_data;
        }

        throw new Error(`Unsupported WISE bin. format header data type: ${header.header_data_type}`);
    }

    unpack(withData = true) {
        const header = this.header();

        const header_data = this.headerData(header);

        if (!(header.payload_bits === 8 || header.payload_bits === 16 || header.payload_bits === 32)) {
            throw new Error(`Unsupported WISE bin. format precision: ${header.payload_bits}`);
        }

        if (withData) {
            const sizesTotal = HEADER_V1_SIZE + header.header_data_length + header.payload_length;
            if (this.buffer.byteLength !== sizesTotal) {
                throw new Error(`Error with sizes. Buffer is: ${this.buffer.byteLength}. Sizes total: ${sizesTotal}`);
            }

            switch (header.payload_bits) {
                case 8:
                    header_data.data = new Uint8Array(this.buffer, HEADER_V1_SIZE + header.header_data_length, header.payload_length);
                    break;
                case 16:
                    header_data.data = new Uint16Array(this.buffer, HEADER_V1_SIZE + header.header_data_length, header.payload_length / 2);
                    break;
                case 32:
                    header_data.data = new Uint32Array(this.buffer, HEADER_V1_SIZE + header.header_data_length, header.payload_length / 4);
                    break;
            }
        } else {
            switch (header.payload_bits) {
                case 8:
                    header_data.data = new Uint16Array(header.payload_length);
                    break;
                case 16:
                    header_data.data = new Uint16Array(header.payload_length / 2);
                    break;
                case 32:
                    header_data.data = new Uint16Array(header.payload_length / 4);
                    break;
            }
        }

        // console.log(header_data)

        return header_data;
    }

    json() {
        // Initialize TextDecoder with UTF-8 encoding
        const decoder = new TextDecoder('utf-8');

        try {
            // Decode the ArrayBuffer into a string
            const jsonString = decoder.decode(this.buffer);

            // Parse the JSON string into a JavaScript object
            const jsonObject = JSON.parse(jsonString);

            return jsonObject;
        } catch (error) {
            // Handle and rethrow errors for higher-level handling
            console.error('Failed to parse JSON from ArrayBuffer:', error);
            throw error;
        }
    }

    static async stream(response, updateCb) {
        // Check if streaming is supported
        if (!window.ReadableStream) {
            throw new Error("Streaming not supported in this browser.");
        }

        const reader = response.getReader();

        let headerParsed = false;

        let header;
        let scan;

        let payloadIdx = 0;

        let leftover = new Uint8Array(0);

        // ReadableStream default reader: read chunks until 'done' is true
        while (true) {
            const {
                done,
                value
            } = await reader.read();
            if (done) {
                // No more data, break out
                break;
            }

            // console.log('download', value.length)

            let headerJustParsed = false;
            if (! headerParsed) {
                // Wait for atleast 1024 bytes to have downloaded
                // Should be enough for the header and header data
                if (leftover.length < 1024) {
                    const combined = new Uint8Array(leftover.length + value.length);
                    combined.set(leftover, 0);
                    combined.set(value, leftover.length);

                    leftover = combined

                    if (leftover.length < 1024) {
                        continue;
                    }
                }

                const packet = new Packet(leftover.buffer);

                if (packet.isBinary()) {
                    header = packet.header();
                    scan = packet.unpack(false);
                }
                else {
                    throw new Error('Streaming non-binary data is not supported');
                }

                scan.data.fill(0);

                leftover = leftover.subarray(header.header_length + header.header_data_length);

                headerParsed = true;
                headerJustParsed = true;

                // console.log('header parsed')
            }

            // Parse data

            // Combine leftover bytes with the new chunk
            let combined;
            if(headerJustParsed) {
                combined = leftover;
            }
            else {
                combined = new Uint8Array(leftover.length + value.length);
                combined.set(leftover, 0);
                combined.set(value, leftover.length);
            }

            // Now we process the combined data 2 bytes at a time
            let offset = 0;
            while (offset + 1 < combined.length) {
                const lowByte = combined[offset];
                const highByte = combined[offset + 1];

                // Combine bytes into one 16-bit value (assuming little-endian):
                scan.data[++payloadIdx] = (highByte << 8) | lowByte;

                offset += 2; // Move forward by 2 bytes
            }

            // Whatever remains (0 or 1 byte) will be leftover for the next loop
            leftover = combined.subarray(offset);

            // console.log('update')

            updateCb(scan)
        }

        // console.log('done')

        return scan;
    }
}
