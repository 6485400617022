import { createHttpClient } from './http'
import UrlHash from '@/tools/url-hash'

class Api {
  constructor() {
    this.httpClient = createHttpClient()

    // Set custom server if specified in URL
    const customServer = this.getCustomServer()
    if (customServer) {
      this.httpClient.setServer(customServer)
    }
  }

  getCustomServer() {
    const params = new UrlHash()
    return params.has('data_server')
      ? params.get('data_server')
      : null
  }

  url() {
    return this.httpClient.getCurrentServer()
  }

  instance() {
    return this.httpClient
  }
}

export default new Api()
