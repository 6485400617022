<script lang="ts" setup>
import PlaybackModal from '../logic/Hazcams/PlaybackModal.vue'
import { useHazcams } from '../logic/Hazcams'
import { useRoute } from 'vue-router'
import { ref } from 'vue'

const route = useRoute()

const feature = ref(null);
const errorText = ref(null);

const hazcams = useHazcams();

(async () => {
    try {
        await hazcams.hazcams.fetchData()
        feature.value = hazcams.hazcams.getStations().find(feature => feature.properties.id === route.params.id)
    } catch (error) {
        errorText.value = `Failed to load Hazcam station. Please try again later.`;
    }
})()
</script>

<template>
  <div class="min-h-screen flex flex-col items-center justify-center">
    <div>
        <PlaybackModal :feature="feature" v-if="feature" />
        <div v-if="errorText" class="bg-red-50 dark:bg-red-900/20 border-2 border-red-200 dark:border-red-800 rounded-lg p-4 max-w-lg">
            <div class="flex">
              <p class="text-sm text-red-700 dark:text-red-200" v-text="errorText"></p>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
body.page-hazcams-station {
  background-color: #fff !important;
}
</style>

<style scoped>
#userback_button_container {
  display: none !important;
}
</style>