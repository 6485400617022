<template>
  <Transition name="slide-fade">
    <div class="inspector absolute left-1/2 top-1/2" :class="{
      'radar-drawer-open': shouldApplyYOffset
    }">
      <div class="relative">
        <Icon icon="game-icons:convergence-target" class="inline size-8" />
        <div ref="glass" class="color absolute h-10 w-10" :class="{
          'visible': active,
          'invisible': !active,
        }" :style="`background-color: ${color}`"></div>
        <div v-show="active" class="value-container absolute">
          <div class="value" v-html="value_str"></div>
          <div class="beam-height">{{ beam_height_kft.toFixed(1) }} kft</div>
        </div>
        <div v-show="!active" class="no-value-container absolute">
          <div class="value">No data</div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import { distance } from '@turf/turf'
import { Icon } from '@iconify/vue'
import { beamHeight } from '@/logic/Radar/helpers'
import { useRadarTowersStore } from '@/stores/radar_towers'
import { useAppStore } from '@/stores/app'

import { PRT_VALUE_RANGE, PRT_RAIN_VALUE_MAX, PRT_SNOW_VALUE_MIN, PRT_SNOW_VALUE_MAX, PRT_MIXED_VALUE_MIN, PRT_MIXED_VALUE_MAX } from '@/data/radar_products.js'

import { getHCAClassification } from '@/tools/helpers'

const props = defineProps<{
  colortable: any
}>()

const radarTowersStore = useRadarTowersStore()
const appStore = useAppStore()

const active = ref(true)
const color = ref('rgba(0,0,0,0)')
const value_str = ref('')
const beam_height_kft = ref(0)
const glass = ref<HTMLElement | null>(null)

const shouldApplyYOffset = computed(() => {
  return appStore.radar_drawer_open && window.innerWidth < 640
})

const getMapPositionFromGlass = () => {
  if (!glass.value) return null

  const glassRect = glass.value.getBoundingClientRect()
  const mapElement = window.map.getContainer()
  const mapRect = mapElement.getBoundingClientRect()

  // Calculate the position relative to the map
  const relativeX = glassRect.left + (glassRect.width / 2) - mapRect.left
  const relativeY = glassRect.top + (glassRect.height / 2) - mapRect.top

  // Convert the pixel coordinates to map coordinates
  const point = window.map.unproject([relativeX, relativeY])
  return point
}

const updateInspectorValue = () => {
  if (!glass.value || radarTowersStore.scanDatetime === null) return

  const position = getMapPositionFromGlass()
  if (!position) return

  const point = window.map.radar.renderer.getValueAt(position)

  if (point === null) {
    color.value = 'rgba(0,0,0,0)'
    value_str.value = 'No data'
  } else {
    color.value = `rgba(${point.rgbaColor?.join(', ')})`

    const groupId = radarTowersStore.activeProductGroup.id;

    if(groupId === 'HCA') {
      const classification = getHCAClassification(point.value);

      value_str.value = classification;
    }
    else if(groupId === 'PRT') {
      let value = point.value;
      let prt = 'Rain';
      if(value > PRT_SNOW_VALUE_MIN && value <= PRT_SNOW_VALUE_MAX) {
        prt = 'Snow';
        value-=(PRT_VALUE_RANGE * 1);
      }
      else if(value > PRT_MIXED_VALUE_MIN && value <= PRT_MIXED_VALUE_MAX) {
        prt = 'Mixed';
        value-=(PRT_VALUE_RANGE * 2);
      }

      value_str.value = `${value.toFixed(1)} ${radarTowersStore.activeProductGroupUnit}<br>(${prt})`;
    }
    else {
      value_str.value = `${point.value.toFixed(1)} ${radarTowersStore.activeProductGroupUnit}`;
    }
  }

  const dist = distance(position.toArray(), radarTowersStore.activeTower.geometry.coordinates, { units: "meters" })

  // TODO
  // Fix this, as this assumes the radar max range is 400km
  active.value = dist < 400 * 1000;

  const altitude = radarTowersStore.activeTower.geometry.coordinates[2] ?? 0;

  // TODO
  // Bug here, fix this...
  // We need to use the real elevation angle (0.5 is typical but not always)
  const bh = altitude + beamHeight(dist, 0.5)

  // Convert meters to kilo-feet
  beam_height_kft.value = bh * 0.00328083989;
}

// Watch for changes that should trigger an update
watch(() => radarTowersStore.scanDatetime, (newValue) => {
  if (newValue !== null) {
    updateInspectorValue()
  }
})

watch([
  () => appStore.radar_drawer_open,
  () => appStore.colortable_bar_open
], () => {
  setTimeout(updateInspectorValue, 310)
})

onMounted(() => {
  window.map.on('move', updateInspectorValue)
  window.map.on('zoom', updateInspectorValue)
  updateInspectorValue()
})

onUnmounted(() => {
  window.map.off('move', updateInspectorValue)
  window.map.off('zoom', updateInspectorValue)
})
</script>

<style scoped>
.inspector {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  pointer-events: none;
}

.inspector.radar-drawer-open {
  transition: margin-top 300ms;
  /* Substract half the height of the radar drawer */
  margin-top: -215px;
}

.inspector .color {
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #2C3D50;
}

.inspector .value-container {
  width: 80px;
  margin-left: -25px;
  margin-top: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  border: 1px solid #2C3D50;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

.inspector .value-container .value {
  font-weight: bold;
}

.inspector .value-container .beam-height {
  font-weight: bold;
}

.inspector .no-value-container {
  width: 60px;
  margin-left: -15px;
  margin-top: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  border: 1px solid #2C3D50;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}
.inspector .no-value-container .value {
  font-weight: bold;
}
</style>
