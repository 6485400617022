import rollbar from './rollbar'

export default {
  install(app) {
    // Assign a global handler for uncaught errors propagating from within the application.
    app.config.errorHandler = (error, vm, info) => {
      console.error(error)

      const errString = error.toString();

      const ignoreErrorStrings = [
        "can't access dead object",
      ];

      const foundIgnore = ignoreErrorStrings.find((errStr) => {
        return errString.includes(errStr);
      }) !== undefined;

      if(foundIgnore) {
        console.log('Ignoring error...');
        return;
      }

      if(import.meta.env.PROD) {
        rollbar.error(error, { vueComponent: vm, info })
      }
    };

    // Assign a custom handler for runtime warnings from Vue.
    app.config.warnHandler = (warning, vm, info) => {
      console.warn(warning)

      if(import.meta.env.PROD) {
        rollbar.warning(warning, { vueComponent: vm, info })
      }
    };

    app.provide('rollbar', rollbar);
  }
}
