<script setup lang="ts">
import { useTemplateRef, onMounted } from 'vue'
import SettingsSection from './MapSettings/SettingsSection.vue';
import RegionSettings from './MapSettings/RegionSettings.vue';
import StyleSettings from './MapSettings/StyleSettings.vue';
import { useMapSettingsStore } from '@/stores/settings/map';
import { useMapStyle } from '@/logic/Composables/MapStyle'

const mapSettingsStore = useMapSettingsStore();

mapSettingsStore.$subscribe((mutation, state) => {
  mapSettingsStore.applyToMapStyle();
});

const onResetClick = () => {
  mapSettingsStore.reset();

  useMapStyle().defaultTiles()
};
</script>

<template>
  <div class="scrollable-content" ref="scrollable-content">
    <div class="space-y-6 pr-3 sm:pr-6">
      <StyleSettings />

      <RegionSettings
        title="Country Settings"
        kind="country"
        :settings="mapSettingsStore.country"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('country', settings)"
      />

      <RegionSettings
        title="State Settings"
        kind="state"
        :settings="mapSettingsStore.state"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('state', settings)"
      />

      <RegionSettings
        title="County Settings"
        kind="county"
        :settings="mapSettingsStore.county"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('county', settings)"
      />

      <RegionSettings
        title="Settlement Settings"
        kind="settlement"
        :settings="mapSettingsStore.settlement"
        @update:settings="settings => mapSettingsStore.updateRegionSettings('settlement', settings)"
      />

      <div v-if="mapSettingsStore.anyChanged">
        <button
          class="w-full mt-10 px-2.5 py-2 pt-1.5 text-sm font-medium
                 text-white dark:text-gray-100
                 bg-blue-700 dark:bg-blue-600
                 hover:bg-blue-800 dark:hover:bg-blue-700
                 rounded-lg"
          @click="onResetClick"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.simple-modal-bottom-50 .scrollable-content {
  max-height: calc(50vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
  @apply dark:bg-ww-blue;
}
</style>