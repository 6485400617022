enum ShaderKey {
  MERC_RADAR_VSH,
  MERC_RADAR_FSH,
  MERC_RADAR_RAW_FSH,
  RADAR_FLAT_VSH,
  RADAR_FLAT_FSH,

  GLOBE_RENDER_VSH,
  GLOBE_RENDER_FSH,

  FLAT_SDF_VSH,
  FLAT_SDF_FSH
}

type ShaderDict = {
  [key: number]: string;
};

var shaderDict: ShaderDict = {};

const GLSL_LATLNG_TO_MERC = `
const float PI = 3.1415926538;

vec2 latLngToMerc(in vec2 pos) {
float x = (180.0 + pos.x) / 360.0;
float y = (180.0 - (180.0 / PI * log(tan(PI / 4.0 + pos.y * PI / 360.0)))) / 360.0;

return vec2(x, y);
}
`
const DISTANCE_FUNC = `
const float earthRadius = 6371008.8;

float radiansToLength(float radians, float factor) {
    return radians * factor;
}

float dist(vec2 from, vec2 to, float factor) {
    float dLat = radians(to.y - from.y);
    float dLon = radians(to.x - from.x);
    float lat1 = radians(from.y);
    float lat2 = radians(to.y);

    float a = pow(sin(dLat / 2.0), 2.0) + pow(sin(dLon / 2.0), 2.0) * cos(lat1) * cos(lat2);
    return radiansToLength(2.0 * atan(sqrt(a), sqrt(1.0 - a)), factor);
}

vec2 lngLatToTileUV(vec2 lngLat, float zoom) {
const float PI = 3.1415926535897932384626433832795;
    float latRad = radians(lngLat.y);
    float n = pow(2.0, zoom);
    float xtile = n * ((lngLat.x + 180.0) / 360.0);
    float ytile = n * (1.0 - (log(tan(latRad) + 1.0 / cos(latRad)) / PI)) / 2.0;
    return vec2(xtile, ytile);
}

vec2 tileUVToLngLat(vec2 tileUV, float zoom) {
const float PI = 3.1415926535897932384626433832795;
    float n = pow(2.0, zoom);
    float lon = tileUV.x / n * 360.0 - 180.0;
    float latRad = atan(sinh(PI * (1.0 - 2.0 * tileUV.y / n)));
    float lat = degrees(latRad);
    return vec2(lon, lat);
}

float azimuthBetween(vec2 from, vec2 to) {
    float dLon = radians(to.x - from.x);
    float lat1 = radians(from.y);
    float lat2 = radians(to.y);

    float y = sin(dLon) * cos(lat2);
    float x = cos(lat1) * sin(lat2) - sin(lat1) * cos(lat2) * cos(dLon);
    float azimuth = degrees(atan(y, x));

    return mod(azimuth + 360.0, 360.0);
}
`;

const VALUE_FUNCTIONS = `
float getNormalizedValue(in sampler2D valuesTex, in vec2 norm_uv, out float no_value) {
    vec4 vcol = texture(valuesTex, norm_uv);
    float decoded_val = float(vcol.a * 255.0 + vcol.b * 255.0 * 256.0);
    no_value = float(decoded_val >= 1.0);
    float value = (decoded_val - 1.0) / (65535.0 - 1.0);
    return value;
}

vec4 getRawValue(in sampler2D valuesTex, in vec2 norm_uv) {
    vec4 vcol = texture(valuesTex, norm_uv);
    return vcol;
}

vec4 applyFilters(inout vec4 tcol, in float value, in float no_value, in vec2 fil, in float opacity) {
    float v_filter = floor(float(value >= fil.x && value <= fil.y));
    tcol *= no_value * v_filter;
    tcol.a *= opacity;
    return tcol;
}
`;

const COLORMAP_FUNCTIONS = `
vec4 getColorFromValue(float value, in sampler2D colorsTex, in float minimum, in float maximum, in float colormap_length) {
    value = min(max(value, minimum), maximum);
    int colorIndex = int( ((value - minimum) / (maximum - minimum)) * (colormap_length - 1.0));
    vec4 tcol = texelFetch(colorsTex, ivec2(colorIndex, 0.0), 0);
    return tcol;
}
`;

shaderDict[ShaderKey.MERC_RADAR_VSH] = `#version 300 es
precision highp float;
in vec4 a_pos;
uniform mat4 u_matrix;
uniform vec2 u_radarCenter;
uniform vec2 u_dataSize;

uniform vec2 u_radarRes;

out vec2 texUV;

${GLSL_LATLNG_TO_MERC}

void main() {
    vec2 p = a_pos.xy;
    vec2 center = latLngToMerc(u_radarCenter);

    float a = a_pos.w;
    texUV = vec2(a_pos.z, a);

    gl_Position = u_matrix * vec4(p, 0.0, 1.0);
}
`;



shaderDict[ShaderKey.MERC_RADAR_FSH] = `#version 300 es
precision highp float;
precision highp sampler2D;
uniform vec3 u_color;

uniform sampler2D u_colorsTex;
uniform sampler2D u_valuesTex;
uniform float minimum;
uniform float maximum;
uniform vec2 u_filter;
uniform float opacity;
uniform float colormap_length;

uniform vec2 u_dataRange;
uniform vec2 u_pixelMark;
uniform vec2 u_dataSize;
uniform vec2 u_radarRes;

in vec2 texUV;
out vec4 fragColor;

${VALUE_FUNCTIONS}
${COLORMAP_FUNCTIONS}

void main() {
    vec2 tx = texUV;
    tx.x = (tx.x / u_radarRes.x);
    tx.y = (tx.y / u_radarRes.y);

    tx.y += u_radarRes.y * float(tx.y < 0.0);
    ivec2 tuv = ivec2(floor(vec2(tx.x * u_dataSize.x, tx.y * u_dataSize.y)));
    vec2 tnorm = vec2(texUV.x / u_dataSize.x, texUV.y / u_dataSize.y);
    tnorm = tx;

    float no_value;
    float value = getNormalizedValue(u_valuesTex, tnorm, no_value);
    value = u_dataRange.x + value * (u_dataRange.y - u_dataRange.x);

    vec4 tcol = getColorFromValue(value, u_colorsTex, minimum, maximum, colormap_length);
    fragColor = applyFilters(tcol, value, no_value, u_filter, opacity);
}
`;


shaderDict[ShaderKey.MERC_RADAR_RAW_FSH] = `#version 300 es
precision highp float;
precision highp sampler2D;
uniform vec3 u_color;

uniform sampler2D u_colorsTex;
uniform sampler2D u_valuesTex;
uniform float minimum;
uniform float maximum;
uniform vec2 u_filter;
uniform float opacity;
uniform float colormap_length;

uniform vec2 u_dataRange;
uniform vec2 u_pixelMark;
uniform vec2 u_dataSize;
uniform vec2 u_radarRes;

in vec2 texUV;
out vec4 fragColor;

${VALUE_FUNCTIONS}
${COLORMAP_FUNCTIONS}

void main() {
    vec2 tx = texUV;
    tx.x = (tx.x / u_radarRes.x);
    tx.y = (tx.y / u_radarRes.y);

    tx.y += u_radarRes.y * float(tx.y < 0.0);
    ivec2 tuv = ivec2(floor(vec2(tx.x * u_dataSize.x, tx.y * u_dataSize.y)));
    vec2 tnorm = vec2(texUV.x / u_dataSize.x, texUV.y / u_dataSize.y);
    tnorm = tx;

    float no_value;
    float value = getNormalizedValue(u_valuesTex, tnorm, no_value);
    value = u_dataRange.x + value * (u_dataRange.y - u_dataRange.x);

    vec4 rawValue = getRawValue(u_valuesTex, tnorm);

    vec4 tcol = rawValue;
    fragColor = applyFilters(tcol, value, no_value, u_filter, opacity);
}
`;

// GLOBE
shaderDict[ShaderKey.GLOBE_RENDER_VSH] = `#version 300 es
precision highp float;
in vec2 a_pos;
in vec2 a_uv;

out vec2 tex;
uniform vec3 u_tile;

void main() {
    tex = (u_tile.xy + a_uv);
    gl_Position = vec4(a_pos, 0.0, 1.0);
}
`;

shaderDict[ShaderKey.GLOBE_RENDER_FSH] = `#version 300 es
precision highp float;
precision highp sampler2D;

in vec2 tex;
out vec4 fragColor;

uniform sampler2D u_flatTex;
uniform vec2 u_bbmin;
uniform vec2 u_bbmax;
uniform vec3 u_tile;
uniform vec2 u_location;

uniform vec2 u_dataRange;
uniform vec2 u_dataSize;
uniform float u_azimuthStart;
uniform float u_gateSize;
uniform float u_gateStart;
uniform sampler2D u_colorsTex;
uniform sampler2D u_valuesTex;
uniform float minimum;
uniform float maximum;
uniform vec2 u_filter;
uniform float opacity;
uniform float colormap_length;


${DISTANCE_FUNC}
${VALUE_FUNCTIONS}
${COLORMAP_FUNCTIONS}

void main() {
    float tiles_count = pow(2.0, u_tile.z);

    vec2 tloc = tileUVToLngLat(tex, u_tile.z);
    float dt = dist(tloc, u_location, earthRadius);

    float l = dt;
    float r = u_gateStart + u_gateSize * u_dataSize.x;
    float rx = u_gateStart;

    // Discard
    float pval = (l - rx) / (r - rx);
    if(pval > 1.0 || pval < 0.0) discard;

    float polarx = clamp(pval, 0.0, 1.0);
    float theta = azimuthBetween(u_location, tloc);
    if(theta < 0.0) theta += 360.0;
    theta /= 360.0;

    float no_value;
    float value = getNormalizedValue(u_valuesTex, vec2(polarx, theta), no_value);
    value = u_dataRange.x + value * (u_dataRange.y - u_dataRange.x);

    vec4 tcol = getColorFromValue(value, u_colorsTex, minimum, maximum, colormap_length);
    fragColor = applyFilters(tcol, value, no_value, u_filter, opacity);
}
`;






function getShader(name: ShaderKey) {
  if (shaderDict[name]) {
    return shaderDict[name];
  } else return "";
}

export { ShaderKey, getShader };
