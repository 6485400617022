import { azimuthToBearing, destination } from "@turf/turf";
import { lngLatToTileUV, RenderingContext } from "./util";
import { getShader, ShaderKey } from "./shaders";
import {
  LngLat,
  LngLatBounds,
  LngLatLike,
  MercatorCoordinate,
} from "mapbox-gl";

enum ERRORS {
  TEXTURE_NOT_INIT = "Texture not initialized!",
  BUFFER_NOT_INIT = "Buffer not initialized!",
  BUFFER_FAILED_TO_ALLOCATE = "Buffer failed to allocate!",
  PROGRAM_NOT_INIT = "Program not compiled/invalid program!",
  INVALID_DATA_SIZE = "Invalid data size!",
  EMPTY_DATA_ARRAY = "Empty data array!",
  INVALID_COLORMAP_FORMAT = "Invalid colormap format!",
  COLORMAP_SIZE_EXCEEDED = "Colormap exceeds maximum size!",
  COLORMAP_NOT_SET_BEFORE_DRAW = "Colormap not set! Please set colormap before drawing!",

  DESTROYED_USE = "Radar renderer already destroyed!",
  DATA_NOT_INIT = "Radar data not initialized!",
  WEBGL2_NOT_SUPPORTED = "Webgl 2 context not found!"
}

interface RadarDataDefinition {
  location: number[];
  data: number[];
  meters_to_center_of_first_gate: number;
  meters_between_gates: number;
  azimuth_start: number;
  fill_value: number;
  bbox: number[];
  stats: {
    max: number;
    min: number;
  };
}

interface ColorMap {
  colors: number[][];
  min: number;
  max: number;
}

const RADAR_SEGMENTS_X = 460;
const RADAR_SEGMENTS_Y = 360;

class RadarRenderer {
  private layerId: string;
  private layer: mapboxgl.CustomLayerInterface | null = null;
  private map: mapboxgl.Map;
  drawing: boolean = false;

  private radarData: RadarDataDefinition | null;

  // WEBGL
  private gl: WebGL2RenderingContext;
  private renderContext: RenderingContext;

  private mercRender = false;
  private mercMatrix: number[];
  // WEBGL Buffers
  // Vertices
  private vertices: Float32Array = new Float32Array(0);
  private vxb: WebGLBuffer | null = null;

  // Indices
  private indices: Uint32Array = new Uint32Array(0);
  private ixb: WebGLBuffer | null = null;

  // Quad
  private vxbQuad: WebGLBuffer | null = null;

  // WEBGL Programs
  private mercProgram: WebGLProgram | null = null;
  private rawValuesProgram: WebGLProgram | null = null;
  private globeProgram: WebGLProgram | null = null;

  //WEBGL Textures
  private valuesTexture: WebGLTexture | null = null;
  // Colormap
  private colorMap: number[][] = [];
  private colorsTexture: WebGLTexture | null = null;
  private colorMapSize = 0;
  private MAX_COLOR_MAP_SIZE = 512 * 10;

  // Uniforms
  opacity: number = 1.0;
  fmin: number = -Infinity;
  fmax: number = Infinity;
  cmin: number = -Infinity;
  cmax: number = Infinity;
  private dataMin: number = 0;
  private dataMax: number = 0;
  private bbmin: LngLat = new LngLat(-180, -90);
  private bbmax: LngLat = new LngLat(-180, -90);

  private dataSize: { x: number; y: number } = { x: 0, y: 0 };
  private colorbuf: Uint8Array = new Uint8Array(0);
  private pixelmark = [-1, -1];

  constructor(map: mapboxgl.Map, layerId: string, insertBeforeLayerId: string) {
    this.map = map;
    let gl = map.getCanvas().getContext("webgl2");
    if(!gl) throw new Error(ERRORS.WEBGL2_NOT_SUPPORTED);
    this.gl = gl;
    this.mercMatrix = [];
    this.layerId = layerId;
    this.renderContext = new RenderingContext(this.gl);
    this.layer = null;
    this.radarData = null;

    this.reset();
    this.createLayer(map, layerId, insertBeforeLayerId);
  }

  private createLayer(
    map: mapboxgl.Map,
    layerId: string,
    insertBeforeLayerId: string
  ) {
    this.layer = {
      id: layerId,
      type: "custom",
      renderingMode: "3d",
      onAdd: this.initializeContext.bind(this),
      prerender: this.prerender.bind(this),
      renderToTile: this.renderToTile.bind(this),
      render: this.render.bind(this),
      onRemove: this.cleanup.bind(this),
      shouldRerenderTiles: () => {
        return true;
      },
    };
    map.addLayer(this.layer, insertBeforeLayerId);
  }

  private initializeContext(map: mapboxgl.Map, gl: WebGL2RenderingContext) {
    this.mercProgram = this.renderContext.createProgram(
      getShader(ShaderKey.MERC_RADAR_VSH),
      getShader(ShaderKey.MERC_RADAR_FSH)
    );
    this.rawValuesProgram = this.renderContext.createProgram(
      getShader(ShaderKey.MERC_RADAR_VSH),
      getShader(ShaderKey.MERC_RADAR_RAW_FSH)
    );

    this.globeProgram = this.renderContext.createProgram(
      getShader(ShaderKey.GLOBE_RENDER_VSH),
      getShader(ShaderKey.GLOBE_RENDER_FSH)
    );

    this.vxbQuad = this.renderContext.createStaticBuffer(
      new Float32Array([-1, -1, 0, 0, 1, -1, 1, 0, 1, 1, 1, 1, -1, 1, 0, 1])
    );
    if(this.vxbQuad === null) throw new Error(ERRORS.BUFFER_FAILED_TO_ALLOCATE);
  }

  private prerender(gl: WebGL2RenderingContext, matrix: number[]) {}

  private renderToTile(gl: WebGL2RenderingContext, tileId: MercatorCoordinate) {
    this.mercRender = false;
    if (!this.drawing) return;
    if (this.radarData === null) throw new Error(ERRORS.DATA_NOT_INIT);

    // Globe Program
    if (this.globeProgram === null) throw new Error(ERRORS.PROGRAM_NOT_INIT);
    this.renderContext.setProgram(
      this.globeProgram,
      {
        a_pos: {
          type: this.gl.FLOAT,
          count: 2,
          location: undefined,
          offset: undefined,
        },
        a_uv: {
          type: this.gl.FLOAT,
          count: 2,
          location: undefined,
          offset: undefined,
        },
      },
      undefined,
      undefined
    );

    if (this.vxbQuad === null) throw new Error(ERRORS.BUFFER_NOT_INIT);
    this.renderContext.setBuffer(this.vxbQuad);

    let bbmin_t = lngLatToTileUV(this.bbmin.lng, this.bbmin.lat, tileId.z);
    let bbmax_t = lngLatToTileUV(this.bbmax.lng, this.bbmax.lat, tileId.z);
    let loc = lngLatToTileUV(
      this.radarData.location[0],
      this.radarData.location[1],
      tileId.z
    );

    this.renderContext.setUniforms(this.globeProgram, {
      //u_flatTex: { type: gl.SAMPLER_2D, value: 0 },
      u_tile: { type: gl.FLOAT_VEC3, value: [tileId.x, tileId.y, tileId.z] },
      u_location: {
        type: gl.FLOAT_VEC2,
        value: [this.radarData.location[0], this.radarData.location[1]],
      },
      u_gateSize: {
        type: gl.FLOAT,
        value: this.radarData.meters_between_gates,
      },
      u_gateStart: {
        type: gl.FLOAT,
        value: this.radarData.meters_to_center_of_first_gate,
      },
      u_dataSize: {
        type: gl.FLOAT_VEC2,
        value: [this.dataSize.x, this.dataSize.y],
      },
      //u_azimuthStart: { type: gl.FLOAT, value: this.radarData.azimuth_start },

      minimum: { type: gl.FLOAT, value: this.cmin },
      maximum: { type: gl.FLOAT, value: this.cmax },
      u_filter: { type: gl.FLOAT_VEC2, value: [this.fmin, this.fmax] },
      colormap_length: { type: gl.FLOAT, value: this.colorMapSize },
      u_colorsTex: { type: gl.SAMPLER_2D, value: 0 },
      u_valuesTex: { type: gl.SAMPLER_2D, value: 1 },
      opacity: { type: gl.FLOAT, value: this.opacity },
      u_dataRange: { type: gl.FLOAT_VEC2, value: [this.dataMin, this.dataMax] },
    });

    if (this.colorsTexture === null) throw new Error(ERRORS.TEXTURE_NOT_INIT);
    this.renderContext.setTexture(this.colorsTexture, 0);
    if (this.valuesTexture === null) throw new Error(ERRORS.TEXTURE_NOT_INIT);
    this.renderContext.setTexture(this.valuesTexture, 1);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  }

  private render(gl: WebGL2RenderingContext, matrix: number[]) {
    this.mercRender = true;
    this.mercMatrix = matrix;
    if (!this.drawing) return;
    if (this.radarData === null) throw new Error(ERRORS.DATA_NOT_INIT);
    // Mercator program
    if (this.mercProgram === null) throw new Error(ERRORS.PROGRAM_NOT_INIT);
    this.renderContext.setProgram(
      this.mercProgram,
      {
        a_pos: {
          type: this.gl.FLOAT,
          count: 4,
          location: undefined,
          offset: undefined,
        },
      },
      undefined,
      undefined
    );

    if (this.vxb === null) throw new Error(ERRORS.BUFFER_NOT_INIT);
    this.renderContext.setBuffer(this.vxb);

    if (this.colorsTexture === null) throw new Error(ERRORS.TEXTURE_NOT_INIT);
    this.renderContext.setTexture(this.colorsTexture, 0);
    if (this.valuesTexture === null) throw new Error(ERRORS.TEXTURE_NOT_INIT);
    this.renderContext.setTexture(this.valuesTexture, 1);
    this.renderContext.setUniforms(this.mercProgram, {
      u_matrix: { type: gl.FLOAT_MAT4, value: matrix },
      minimum: { type: gl.FLOAT, value: this.cmin },
      maximum: { type: gl.FLOAT, value: this.cmax },
      u_filter: { type: gl.FLOAT_VEC2, value: [this.fmin, this.fmax] },
      colormap_length: { type: gl.FLOAT, value: this.colorMapSize },
      u_colorsTex: { type: gl.SAMPLER_2D, value: 0 },
      u_valuesTex: { type: gl.SAMPLER_2D, value: 1 },
      opacity: { type: gl.FLOAT, value: this.opacity },
      u_dataRange: { type: gl.FLOAT_VEC2, value: [this.dataMin, this.dataMax] },
      //u_pixelMark: { type: gl.FLOAT_VEC2, value: this.pixelmark},
      //u_azimuthStart : {type: gl.FLOAT, value : this.radarData.azimuth_start},
      u_dataSize: {
        type: gl.FLOAT_VEC2,
        value: [this.dataSize.x, this.dataSize.y],
      },
      u_radarCenter: { type: gl.FLOAT_VEC2, value: [this.radarData.location[0], this.radarData.location[1]] },
      u_radarRes: {
        type: gl.FLOAT_VEC2,
        value: [RADAR_SEGMENTS_X, RADAR_SEGMENTS_Y],
      },
    });
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.enable(gl.BLEND);

    if (this.ixb === null) throw new Error(ERRORS.BUFFER_NOT_INIT);
    this.renderContext.setIndices(this.ixb);
    gl.drawElements(gl.TRIANGLES, this.indices.length, gl.UNSIGNED_INT, 0);
  }

  private deleteBuffer(
    gl: WebGL2RenderingContext,
    buffer: WebGLBuffer | null
  ) {
    if (buffer) {
      gl.deleteBuffer(buffer);
      buffer = null;
    }
    return buffer;
  }

  private cleanup(map: mapboxgl.Map, gl: WebGL2RenderingContext) {
    this.drawing = false;

    const deleteProgram = (
      gl: WebGL2RenderingContext,
      program: WebGLProgram | null
    ) => {
      if (program) {
        gl.deleteProgram(program);
        program = null;
      }
      return program;
    };
    const deleteTexture = (
      gl: WebGL2RenderingContext,
      texture: WebGLProgram | null
    ) => {
      if (texture) {
        gl.deleteTexture(texture);
        texture = null;
      }
      return texture;
    };

    this.vertices = new Float32Array(0);
    this.indices = new Uint32Array(0);

    this.vxb = this.deleteBuffer(gl, this.vxb);
    this.ixb = this.deleteBuffer(gl, this.ixb);
    this.vxbQuad = this.deleteBuffer(gl, this.vxbQuad);

    this.mercProgram = deleteProgram(gl, this.mercProgram);
    this.globeProgram = deleteProgram(gl, this.globeProgram);

    this.valuesTexture = deleteTexture(gl, this.valuesTexture);
    this.colorsTexture = deleteTexture(gl, this.colorsTexture);
  }
  // Data Settings

  private clearData() {
    const gl = this.gl;
    const w = this.dataSize.x;
    const h = this.dataSize.y;

    this.colorbuf.fill(0x00);

    this.vxb = this.deleteBuffer(gl, this.vxb);
    this.ixb = this.deleteBuffer(gl, this.ixb);

    this.radarData = null;

    if (this.valuesTexture == null) {
      this.valuesTexture = this.renderContext.createTextureSize(
        w,
        h,
        gl.CLAMP_TO_EDGE,
        gl.NEAREST
      );
    }

    gl.bindTexture(gl.TEXTURE_2D, this.valuesTexture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      w,
      h,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this.colorbuf
    );
  }

  private allocateIndices(
    gatesFromCenter: number,
    gatesAround: number,
    startLastLine: number
  ) {
    const radarData = this.radarData;
    if (radarData === null) return;
    const tri_iarr = new Uint32Array(gatesFromCenter * gatesAround * 6);
    let tri_idx = 0;
    let i = 0;
    let d = 0;

    for (d = 0; d < gatesFromCenter - 1; ++d) {
      for (i = 0; i < gatesAround - 1; i++) {
        tri_iarr[tri_idx++] = d * gatesAround + i;
        tri_iarr[tri_idx++] = d * gatesAround + i + gatesAround;
        tri_iarr[tri_idx++] = d * gatesAround + i + gatesAround + 1;
      }

      tri_iarr[tri_idx++] = d * gatesAround + i;
      tri_iarr[tri_idx++] = d * gatesAround + i + gatesAround;
      tri_iarr[tri_idx++] = startLastLine + d + 1;

      for (i = 0; i < gatesAround - 1; i++) {
        tri_iarr[tri_idx++] = d * gatesAround + i;
        tri_iarr[tri_idx++] = d * gatesAround + i + gatesAround + 1;
        tri_iarr[tri_idx++] = d * gatesAround + i + 1;
      }

      tri_iarr[tri_idx++] = d * gatesAround + i;
      tri_iarr[tri_idx++] = startLastLine + d + 1;
      tri_iarr[tri_idx++] = startLastLine + d;
    }

    this.indices = tri_iarr;

    this.ixb = this.renderContext.createIndexBuffer_UInt(this.indices);
    if(this.ixb === null) throw new Error(ERRORS.BUFFER_FAILED_TO_ALLOCATE);
  }

  // setup new data
  setupData(radarData: RadarDataDefinition) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    this.radarData = radarData;
    const gatesFromCenter = RADAR_SEGMENTS_X;
    const gatesAround = RADAR_SEGMENTS_Y;
    const startLastLine = gatesFromCenter * gatesAround;
    const xscale = radarData.dims[1] / (gatesFromCenter - 1);

    const azimuthStart = 0;
    const angleInc = 360.0 / gatesAround;
    let curAngle = 0;
    let bearing, distance, loc;
    let d, i;

    const longitude1 = (radarData.location[0] * Math.PI) / 180;
    const latitude1 = (radarData.location[1] * Math.PI) / 180;

    const sinLatitude1 = Math.sin(latitude1);
    const cosLatitude1 = Math.cos(latitude1);

    // console.time('aa');
    const bounds = new LngLatBounds(
      [radarData.bbox[0], radarData.bbox[1]],
      [radarData.bbox[2], radarData.bbox[3]]
    );
    // console.timeEnd('aa');

    const packed_arr = new Float32Array(
      gatesFromCenter * gatesAround * 4 + gatesFromCenter * 4
    );

    let coordIdx = 0;

    // console.time('a');
    // TODO: Change this for real-time azimuth changes
    for (d = 0; d < gatesFromCenter; ++d) {
      distance =
        radarData.meters_to_center_of_first_gate +
        radarData.meters_between_gates * d * xscale;
      const radians = distance / 6371008.8;
      const cosRadians = Math.cos(radians);
      const sinRadians = Math.sin(radians);

      for (i = 0; i < gatesAround; ++i) {
        bearing = azimuthStart + azimuthToBearing(curAngle);

        const bearingRad = (bearing * Math.PI) / 180;

        const latitude2 = Math.asin(
          sinLatitude1 * cosRadians +
            cosLatitude1 * sinRadians * Math.cos(bearingRad)
        );

        const longitude2 =
          longitude1 +
          Math.atan2(
            Math.sin(bearingRad) * sinRadians * cosLatitude1,
            cosRadians - sinLatitude1 * Math.sin(latitude2)
          );
        const lng = (longitude2 * 180) / Math.PI;
        const lat = (latitude2 * 180) / Math.PI;
        const merc = MercatorCoordinate.fromLngLat([lng, lat]);

        packed_arr[coordIdx++] = merc.x;
        packed_arr[coordIdx++] = merc.y;
        packed_arr[coordIdx++] = d;
        packed_arr[coordIdx++] = i;

        curAngle += angleInc;
      }
    }
    // console.timeEnd('a');

    // console.time('b');
    // Last line : IMPORTANT for element array render!
    for (d = 0; d < gatesFromCenter; ++d) {
      bearing = azimuthStart + azimuthToBearing(azimuthStart);
      loc = destination(
        radarData.location,
        radarData.meters_to_center_of_first_gate +
          radarData.meters_between_gates * d * xscale,
        bearing,
        { units: "meters" }
      );

      const merc = MercatorCoordinate.fromLngLat([
        loc.geometry.coordinates[0],
        loc.geometry.coordinates[1],
      ]);

      // Inline this code for performance
      packed_arr[coordIdx++] = merc.x;
      packed_arr[coordIdx++] = merc.y;
      packed_arr[coordIdx++] = d;
      packed_arr[coordIdx++] = gatesAround;
    }
    // console.timeEnd('b');

    const minll = bounds._sw;
    const maxll = bounds._ne;
    this.bbmin = minll;
    this.bbmax = maxll;

    // console.timeEnd('c');

    // console.time('d');
    this.vertices = packed_arr;
    this.gl.useProgram(this.mercProgram);
    this.vxb = this.renderContext.createStaticBuffer(this.vertices);
    if(this.vxb === null) throw new Error(ERRORS.BUFFER_FAILED_TO_ALLOCATE);

    this.allocateIndices(gatesFromCenter, gatesAround, startLastLine);

    this.dataSize = {
      x: this.radarData.dims[1],
      y: this.radarData.dims[0],
    };
    this.colorbuf = new Uint8Array(this.dataSize.x * this.dataSize.y * 4);
    // console.timeEnd('d');

    // console.time('e');
    this.updateData(radarData);
    // console.timeEnd('e');
  }

  // API for data
  updateData(radarData: RadarDataDefinition) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    const gl = this.gl;
    let w = radarData.dims[1];
    let h = radarData.dims[0];
    if (w == 0 || h == 0) throw new Error(ERRORS.EMPTY_DATA_ARRAY);
    if (w != this.dataSize.x || h != this.dataSize.y)
      throw new Error(ERRORS.INVALID_DATA_SIZE);

    this.dataMax = radarData.range.max;
    this.dataMin = radarData.range.min;

    this.colorbuf.fill(0);

    const bitsMax = radarData.precision - 1;
    const bitsValueMax = (1 << bitsMax) - 1;

    const colorbufSize = this.dataSize.x * this.dataSize.y * 4;

    let e = 0;
    let len = radarData.data.length;
    let val = 0;
    let tval = 0;
    let skip = 0;
    let idx = (this.dataSize.x * 4) * Math.round(((this.dataSize.y / 360.0) * radarData.azimuth_start));

    // Loop through each 16 bit value
    while(e < len) {
      val = radarData.data[e];

      // Check if the value is higher than the max value for 15 bits
      if(val > bitsValueMax) {
        skip = val - bitsValueMax;

        ++e;

        idx+=(skip*4);

        continue;
      }

      // Check we're dealing with a 'real' value
      if(val > 0) {
        // Convert 15 bits to 16 bits...
        tval = (val << 1) - 1;

        idx+=2;

        // TODO, optimise this, remove the modulo
        this.colorbuf[(idx++)%colorbufSize] = 0xff & (tval >> 8);
        this.colorbuf[(idx++)%colorbufSize] = 0xff & tval;
      }
      else {
        idx+=4;
      }

      ++e
    }

    if (this.valuesTexture == null) {
      this.valuesTexture = this.renderContext.createTextureSize(
        w,
        h,
        gl.REPEAT,
        gl.NEAREST
      );
    }

    gl.bindTexture(gl.TEXTURE_2D, this.valuesTexture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      w,
      h,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this.colorbuf
    );
  }

  setColorMapDirect(colormap: number[][]) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    const { gl } = this;
    // This is done avoid infinite memory allocation using color maps
    if (colormap.length > this.MAX_COLOR_MAP_SIZE) {
      throw new Error(ERRORS.COLORMAP_SIZE_EXCEEDED);
    }

    if (this.colorsTexture == null) {
      this.colorsTexture = this.renderContext.createTextureSize(
        this.MAX_COLOR_MAP_SIZE,
        1,
        gl.CLAMP_TO_EDGE,
        gl.NEAREST
      );
    }

    this.colorMapSize = colormap.length;

    gl.bindTexture(gl.TEXTURE_2D, this.colorsTexture);
    const textureBuffer = new Uint8Array(colormap.length * 4);
    textureBuffer.fill(0);
    let idx = 0;
    for (let i = 0; i < colormap.length; i++) {
      if (colormap[i].length < 3) {
        console.warn("Invalid color value in colormap!");
        idx += 4;
        continue;
      }
      textureBuffer[idx++] = colormap[i][0];
      textureBuffer[idx++] = colormap[i][1];
      textureBuffer[idx++] = colormap[i][2];
      textureBuffer[idx++] = 0xff;
    }
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      colormap.length,
      1,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      textureBuffer
    );

    this.colorMap = colormap;
  }

  // Public interface
  setColormap(colormap: ColorMap) {
    const isStringArray = (array: any[]) => {
      return (
        Array.isArray(array) && array.every((item) => typeof item === "string")
      );
    };
    const isNumberMatrix = (matrix: any[][]) => {
      return (
        Array.isArray(matrix) &&
        matrix.every(
          (row) =>
            Array.isArray(row) && row.every((item) => typeof item === "number")
        ) &&
        matrix.length > 0 &&
        matrix[0].length >= 3
      );
    };

    const hexColmapToRGBA = (colormap: Array<string>) => {
      if (colormap.length === 0) return [];
      if (colormap[0].length !== 7 && colormap[0].length !== 9)
        throw new Error("Invalid colormap format");
      return colormap.map((color) => {
        return [
          parseInt(color.slice(1, 3), 16),
          parseInt(color.slice(3, 5), 16),
          parseInt(color.slice(5, 7), 16),
          color.length === 9 ? parseInt(color.slice(7, 9), 16) : 255,
        ];
      });
    };

    if (isStringArray(colormap.colors)) {
      const numbersColormap: number[][] = hexColmapToRGBA(colormap.colors);
      this.setColorMapDirect(numbersColormap);
    } else if (isNumberMatrix(colormap.colors)) {
      this.setColorMapDirect(colormap.colors);
    } else {
      throw new Error(ERRORS.INVALID_COLORMAP_FORMAT);
    }

    if (colormap.min !== this.cmin || colormap.max !== this.cmax) {
      this.setMinMax(colormap.min, colormap.max);
    }
  }

  setMinMax(min: number, max: number) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    this.cmin = min;
    this.cmax = max;

    this.map.triggerRepaint();
  }

  setFilter(min: number, max: number) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    this.fmin = min;
    this.fmax = max;

    this.map.triggerRepaint();
  }

  setOpacity(value: number) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    this.opacity = value;

    this.map.triggerRepaint();
  }

  draw(radarData: RadarDataDefinition) {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    if (this.colorMapSize == 0 || this.colorsTexture == null)
      throw new Error(ERRORS.COLORMAP_NOT_SET_BEFORE_DRAW);

    // console.log(radarData)

    // Hack here to deal with old radar scans...
    if(radarData.dims === undefined && radarData.polar !== undefined) {
      radarData.precision = 16;

      const bitsMax = radarData.precision - 1;
      const bitsValueMax = (1 << bitsMax) - 1;

      // console.log(bitsValueMax)

      radarData.dims = [radarData.polar.length, radarData.polar[0].length];

      radarData.range = {
        min: radarData.stats.min,
        max: radarData.stats.max
      };

      // console.log(radarData.dims)

      // console.log(radarData.range.max - radarData.range.min)

      radarData.data = new Uint16Array(radarData.dims[0] * radarData.dims[1]);
      radarData.data.fill(0);

      let x = 0;
      let v = 0;
      for(let i = 0; i < radarData.polar.length; ++i) {
        for(let j = 0; j < radarData.polar[i].length; ++j) {
          v = radarData.polar[i][j];

          // radarData.data[x] = 15000

          if(v !== radarData.fill_value) {
            // console.log(v, (v / (radarData.range.max - radarData.range.min)), bitsValueMax)
            radarData.data[x] = Math.min(1, (v / (radarData.range.max - radarData.range.min))) * bitsValueMax
          }

          x+=1;
        }
      }

      // console.log(radarData.data)
    }

    let w = radarData.dims[0] > 0 ? radarData.dims[1] : 0;
    let h = radarData.dims[0];
    if (w == 0 || h == 0) throw new Error(ERRORS.EMPTY_DATA_ARRAY);
    if (
      w != this.dataSize.x ||
      h != this.dataSize.y ||
      this.radarData?.location[0] !== radarData.location[0] ||
      this.radarData?.location[1] !== radarData.location[1]
    ) {
      // console.log('Setting up new data');
      this.radarData = radarData;
      this.setupData(radarData);
    } else {
      // console.log('Updating existing data');
      if (this.radarData === null) throw new Error(ERRORS.DATA_NOT_INIT);
      this.radarData = radarData;
      this.updateData(radarData);
    }

    this.drawing = true;
    this.map.triggerRepaint();
  }

  private getColorFromValue(value: number) {
    if (value < this.cmin || value > this.cmax) return null;
    let colorIndex = Math.floor(
      ((value - this.cmin) / (this.cmax - this.cmin)) * (this.colorMapSize - 1)
    );
    return this.colorMap[colorIndex];
  }

  private renderRawValues(gl: WebGL2RenderingContext, matrix: number[]) {
    if (!this.drawing) return;
    if (this.radarData === null) throw new Error(ERRORS.DATA_NOT_INIT);
    // Mercator program
    if (this.rawValuesProgram === null)
      throw new Error(ERRORS.PROGRAM_NOT_INIT);
    this.renderContext.setProgram(
      this.rawValuesProgram,
      {
        a_pos: {
          type: this.gl.FLOAT,
          count: 4,
          location: undefined,
          offset: undefined,
        },
      },
      undefined,
      undefined
    );

    if (this.vxb === null) throw new Error(ERRORS.BUFFER_NOT_INIT);
    this.renderContext.setBuffer(this.vxb);

    if (this.colorsTexture === null) throw new Error(ERRORS.TEXTURE_NOT_INIT);
    this.renderContext.setTexture(this.colorsTexture, 0);
    if (this.valuesTexture === null) throw new Error(ERRORS.TEXTURE_NOT_INIT);
    this.renderContext.setTexture(this.valuesTexture, 1);
    this.renderContext.setUniforms(this.rawValuesProgram, {
      u_matrix: { type: gl.FLOAT_MAT4, value: matrix },
      //minimum: { type: gl.FLOAT, value: this.cmin },
      //maximum: { type: gl.FLOAT, value: this.cmax },
      u_filter: { type: gl.FLOAT_VEC2, value: [this.fmin, this.fmax] },
      //colormap_length: { type: gl.FLOAT, value: this.colorMapSize },
      //u_colorsTex: { type: gl.SAMPLER_2D, value: 0 },
      u_valuesTex: { type: gl.SAMPLER_2D, value: 1 },
      opacity: { type: gl.FLOAT, value: this.opacity },
      u_dataRange: { type: gl.FLOAT_VEC2, value: [this.dataMin, this.dataMax] },
      //u_pixelMark: { type: gl.FLOAT_VEC2, value: this.pixelmark},
      //u_azimuthStart : {type: gl.FLOAT, value : this.radarData.azimuth_start},
      u_dataSize: {
        type: gl.FLOAT_VEC2,
        value: [this.dataSize.x, this.dataSize.y],
      },
      u_radarCenter: { type: gl.FLOAT_VEC2, value: this.radarData.location },
      u_radarRes: {
        type: gl.FLOAT_VEC2,
        value: [RADAR_SEGMENTS_X, RADAR_SEGMENTS_Y],
      },
    });

    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.disable(gl.BLEND);
    if (this.ixb === null) throw new Error(ERRORS.BUFFER_NOT_INIT);
    this.renderContext.setIndices(this.ixb);
    gl.drawElements(gl.TRIANGLES, this.indices.length, gl.UNSIGNED_INT, 0);
  }

  getValueAt(lngLat: LngLat) {
    if (!this.drawing || !this.mercRender) return;

    this.renderRawValues(this.gl, this.mercMatrix);
    let pixelPos = this.map.project(lngLat);
    let pixelValue = this.renderContext?.getPixel(
      pixelPos.x * window.devicePixelRatio,
      pixelPos.y * window.devicePixelRatio
    );
    if (!pixelValue) {
      this.map.triggerRepaint();
      return null;
    }
    if (
      pixelValue[0] == 0 &&
      pixelValue[1] == 0 &&
      pixelValue[2] == 0 &&
      pixelValue[3] == 0
    ) {
      this.map.triggerRepaint();
      return null;
    }

    // remap value
    let raw = pixelValue[3] + pixelValue[2] * 256;
    let actual_value =
      this.dataMin + (raw / (65535 - 1)) * (this.dataMax - this.dataMin);
    actual_value = parseFloat(actual_value.toFixed(2));

    // Get color directly from screen if needed
    this.render(this.gl, this.mercMatrix);
    let pixelColor = this.renderContext?.getPixel(
      pixelPos.x * window.devicePixelRatio,
      pixelPos.y * window.devicePixelRatio
    );
    if (!pixelColor) {
      return null;
    }
    if (pixelColor[3] == 0) {
      return null;
    }

    let colorValue = pixelColor;

    const rgbToHex = (r: number, g: number, b: number): string => {
      r = Math.max(0, Math.min(255, r));
      g = Math.max(0, Math.min(255, g));
      b = Math.max(0, Math.min(255, b));

      function padHex(value: number): string {
        const hex = value.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }

      return `#${padHex(r)}${padHex(g)}${padHex(b)}`;
    };
    let hexValue =
      colorValue === null
        ? "NULL"
        : rgbToHex(colorValue[0], colorValue[1], colorValue[2]);

    this.map.triggerRepaint(); // TODO: Use external buffer to draw the values if this causes a performance hit
    return {
      value: actual_value,
      rgbaColor: colorValue,
      hexColor: hexValue,
    };
  }

  getData(): RadarDataDefinition | null {
    return this.radarData;
  }

  clear() {
    if (this.destroyed_) throw new Error(ERRORS.DESTROYED_USE);
    this.clearData();
    this.drawing = false;
    this.map.triggerRepaint();
  }

  reset() {
    this.opacity = 1.0;
    this.fmin = -Infinity;
    this.fmax = Infinity;
  }

  destroyed_ = false;
  destroy() {
    this.reset();
    this.map.removeLayer(this.layerId);

    this.destroyed_ = true;
  }
}

export { RadarRenderer };
