import mapboxgl from 'mapbox-gl'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useHead } from '@unhead/vue'
import { distance } from '@turf/turf'

import { PAGE_TITLE } from '@/brand'

import api from '@/logic/Api'
import socket from '@/logic/Socket'

import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'

import satellites from '@/data/Satellite/list.js'

import { useSatelliteStore } from '@/stores/satellite'

import SimpleModal from './Modals/Templates/Simple.vue'
import SatelliteProductHelpModal from './Satellite/SatelliteProductHelp.vue'

export default class Satellite {
  constructor(map) {
    this.map = map

    this.satelliteStore = useSatelliteStore()

    this.mode = MODE_NONE;

    this.activeSocketRooms = [];

    this.rasterTileSourceId = 'satellite-raster-tile-source'
    this.rasterTileLayerId = 'satellite-raster-tile-layer'

    this.layers = [
      this.rasterTileLayerId
    ];

    this.activeTiles = [];

    this.map.addLayer({
      id: 'top-middle-satellite-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "land-structure-polygon")
  }

  removeTileLayer() {
    if (this.map.getLayer(this.rasterTileLayerId)) {
      this.map.removeLayer(this.rasterTileLayerId);
    }

    if(this.map.getSource(this.rasterTileSourceId)) {
      this.map.removeSource(this.rasterTileSourceId);
    }

    this.activeTiles = [];
  }

  addTileLayer(satellite, product) {
    // console.log(satellite, product)

    const rasterLayer = {
      type: 'raster',
      tiles: product.tiles,
      tileSize: 256,
      maxzoom: product.max_zoom
    };

    const allBounds = [];

    if(satellite.bounds !== undefined) {
      const bounds1 = new mapboxgl.LngLatBounds([satellite.bounds[0], satellite.bounds[1]], [satellite.bounds[2], satellite.bounds[3]]);

      allBounds.push(bounds1);
    }

    if(satellite.id === 'GOES-West') {
      const bounds2 = new mapboxgl.LngLatBounds([180 + (satellite.bounds[0] + 180), satellite.bounds[1]], [180.0, satellite.bounds[3]]);

      allBounds.push(bounds2);
    }

    this.map.addSource(this.rasterTileSourceId, rasterLayer)

    // Monkey patch the tileBounds.contains method with our own
    // To consider multiple bounding boxes
    // In the case of GOES-West, we have a bbox the left and right
    // side of the international date line

    function mercatorXfromLng(lng) {
      return (180 + lng) / 360;
    }

    function mercatorYfromLat(lat) {
      return (180 - (180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360)))) / 360;
    }

    const s = this.map.getSource(this.rasterTileSourceId)
    s.tileBounds = {
      contains: (tileID) => {
        const worldSize = Math.pow(2, tileID.z);

        for(let i = 0; i < allBounds.length; ++i) {
          const level = {
            minX: Math.floor(mercatorXfromLng(allBounds[i].getWest()) * worldSize),
            minY: Math.floor(mercatorYfromLat(allBounds[i].getNorth()) * worldSize),
            maxX: Math.ceil(mercatorXfromLng(allBounds[i].getEast()) * worldSize),
            maxY: Math.ceil(mercatorYfromLat(allBounds[i].getSouth()) * worldSize)
          };
          const hit = tileID.x >= level.minX && tileID.x < level.maxX && tileID.y >= level.minY && tileID.y < level.maxY;

          if(hit) return true;
        }

        return false;
      }
    }

    this.map.addLayer({
      id: this.rasterTileLayerId,
      type: 'raster',
      source: this.rasterTileSourceId,
      paint: {
        // "raster-fade-duration": 0,
        // This disables de-alias
        "raster-resampling": "nearest"
      }
    }, "top-middle-satellite-layer")

    this.activeTiles = product.tiles

    this.satelliteStore.setScanDatetime(product.last_update_at)
  }

  fetchList() {
    return api.instance().get(`/satellite/tiles1/list.json`)
  }

  getSatellite(id) {
    return JSON.parse(JSON.stringify(satellites.find(s => s.id === id)));
  }

  async loadSatelliteData(satellite, product) {
    const list = await this.fetchList()

    const latestSatellite = list.satellites.find(s => s.id === satellite);
    if(latestSatellite === undefined) return console.error('Unable to locate latest satellite', satellite);

    const latestProduct = latestSatellite.products.find(p => p.id === product);
    if(latestProduct === undefined) return console.error('Unable to locate latest product', product);

    // Check if the layer is already rendered
    // And if all the state matches, if so, return
    if(JSON.stringify(this.activeTiles) === JSON.stringify(latestProduct.tiles)) {
      return;
    }

    this.removeTileLayer();
    this.addTileLayer(latestSatellite, latestProduct);
  }

  async turnOnSatellite(satelliteId, product = null) {
    const satellite = this.getSatellite(satelliteId);

    useHead({
      title: `${satellite.name} - ${PAGE_TITLE}`
    })

    this.mode = MODE_REALTIME;

    this.satelliteStore.setActiveSatellite(satellite)

    if(product === null) {
      product = this.satelliteStore.activeProductCode;
    }
    else {
      this.satelliteStore.activeProductCode = product;
    }

    const room = `satellite:${satelliteId}:${product}`
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Satellite update', room, data, `Mode: ${this.mode}`)

      if(this.mode === MODE_REALTIME) {
        // Check that the mapbox source exists...
        const tileSource = this.map.getSource(this.rasterTileSourceId);
        if(tileSource === undefined) return;

        tileSource.setTiles(data.tiles);
        this.activeTiles = data.tiles;
        this.satelliteStore.setScanDatetime(data.last_update_at)
      }
    });

    try {
      await this.loadSatelliteData(satelliteId, product);
    }
    catch(e) {
      console.log('Failed to load latest satellite data', e);
    }
  }

  changeSatelliteProduct(id, productCode) {
    const satellite = this.getSatellite(id);

    if(satellite === undefined) return false

    this.stopListeningToRooms()

    this.satelliteStore.activeProductCode = productCode;

    this.turnOnSatellite(satellite.id)
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  findBestSatelliteFor(location) {
    let closestSat = null;
    let shortestDistance = Infinity;

    satellites.forEach(sat => {
      const dist = distance(location, sat.area_of_coverage.center, { units: "meters" })
      if (dist < shortestDistance && dist <= sat.area_of_coverage.radius_m) {
        shortestDistance = dist;
        closestSat = sat;
      }
    });

    // TODO
    // If a nearby sat does not exist
    // Then use a layer that covers the whole world
    if(closestSat === null) {
      return satellites[0].id;
    }

    return closestSat.id
  }

  openSatelliteHelpModal(satelliteId) {
    const latestSatellite = satellites.find(s => s.id === satelliteId);
    if(latestSatellite === undefined) return;

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: latestSatellite.name
      },
      slots: {
        default: useModalSlot({
          component: SatelliteProductHelpModal,
          attrs: {
            text: latestSatellite.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openSatelliteProductHelpModal(satelliteId, productCode) {
    const latestSatellite = satellites.find(s => s.id === satelliteId);
    if(latestSatellite === undefined) return;

    const latestProduct = latestSatellite.products.find(p => p.id === productCode);
    if(latestProduct === undefined) return;

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: latestProduct.name
      },
      slots: {
        default: useModalSlot({
          component: SatelliteProductHelpModal,
          attrs: {
            text: latestProduct.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  show() {
    for(const layerId of this.layers) {
      if (this.map.getLayer(this.rasterTileLayerId)) this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }

    if(this.satelliteStore.activeSatelliteId === '0') {
      this.turnOnSatellite(satellites[0].id)
    }
    else {
      this.turnOnSatellite(this.satelliteStore.activeSatelliteId)
    }
  }

  hide() {
    this.removeTileLayer();
    this.stopListeningToRooms();

    for(const layerId of this.layers) {
      if (this.map.getLayer(this.rasterTileLayerId)) this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}